import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord, RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { INFERENCE_ROUTE_NAMES } from "./deployment.routes.names";
import { MODEL_SPEC_NAMES } from "../model-spec.routes/model-spec.routes.names";

import { useSettingStore } from "@/stores/setting.store";
import { SettingKeys } from "@/models/setting.model";

export const deploymentRoutes: RunaiRouteRecord = {
  path: "/inferences/new",
  component: () => import("@/views/deployment/deployment-app.vue"),
  children: [
    {
      path: "",
      name: INFERENCE_ROUTE_NAMES.INFERENCE_NEW,
      component: () => import("@/views/deployment/creation/deployment-new.vue"),
      meta: {
        pageTitle: "New inference",
        fullWidth: true,
        requestToLeave: true,
        backPageName: MODEL_SPEC_NAMES.MODEL_SPEC_INDEX,
        resourceType: ResourceType.Deployments,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
      },
    },
    {
      path: "assets",
      name: "inference-assets-app",
      component: () => import("@/views/deployment/creation/deployment-assets-app.vue"), // router-view
      children: [
        {
          path: "",
          name: INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT,
          component: () => import("@/views/deployment/creation/inference-assets-based.vue"),
          meta: {
            pageTitle: "New inference",
            fullWidth: true,
            requestToLeave: true,
            backPageName: MODEL_SPEC_NAMES.MODEL_SPEC_INDEX,
            resourceType: ResourceType.Deployments,
            minPermittedActions: [Action.Create, Action.Update],
            requiresAuth: true,
          },
        },
        {
          path: "environment/new",
          name: INFERENCE_ROUTE_NAMES.INFERENCE_ENVIRONMENT_NEW,
          component: () => import("@/views/environment/environment-new.vue"),
          meta: {
            pageTitle: "New environment",
            fullWidth: true,
            requestToLeave: true,
            backPageName: INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT,
            resourceType: ResourceType.Deployments,
            minPermittedActions: [Action.Create, Action.Update],
            requiresAuth: true,
          },
        },
        {
          path: "compute-resource/new",
          name: INFERENCE_ROUTE_NAMES.INFERENCE_COMPUTE_RESOURCE_NEW,
          component: () => import("@/views/compute-resource/compute-resource-new.vue"),
          meta: {
            pageTitle: "New compute resource",
            fullWidth: true,
            requestToLeave: true,
            backPageName: INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT,
            resourceType: ResourceType.Deployments,
            minPermittedActions: [Action.Create, Action.Update],
            requiresAuth: true,
          },
        },
        {
          path: "data-source/hostpath/new",
          name: INFERENCE_ROUTE_NAMES.INFERENCE_HOST_PATH_NEW,
          component: () => import("@/views/data-source/host-path/host-path-new.vue"),
          meta: {
            pageTitle: "New data source",
            fullWidth: true,
            requestToLeave: true,
            backPageName: INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT,
            resourceType: ResourceType.Deployments,
            minPermittedActions: [Action.Create, Action.Update],
            requiresAuth: true,
          },
        },
        {
          path: "data-source/nfs/new",
          name: INFERENCE_ROUTE_NAMES.INFERENCE_NFS_NEW,
          component: () => import("@/views/data-source/nfs/nfs-new.vue"),
          meta: {
            pageTitle: "New data source",
            fullWidth: true,
            requestToLeave: true,
            backPageName: INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT,
            resourceType: ResourceType.Deployments,
            minPermittedActions: [Action.Create, Action.Update],
            requiresAuth: true,
          },
        },
        {
          path: "data-source/s3/new",
          name: INFERENCE_ROUTE_NAMES.INFERENCE_S3_NEW,
          component: () => import("@/views/data-source/s3/s3-new.vue"),
          meta: {
            pageTitle: "New data source",
            fullWidth: true,
            requestToLeave: true,
            backPageName: INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT,
            resourceType: ResourceType.Deployments,
            minPermittedActions: [Action.Create, Action.Update],
            requiresAuth: true,
          },
        },
        {
          path: "data-source/git/new",
          name: INFERENCE_ROUTE_NAMES.INFERENCE_GIT_NEW,
          component: () => import("@/views/data-source/git/git-new.vue"),
          meta: {
            pageTitle: "New data source",
            fullWidth: true,
            requestToLeave: true,
            backPageName: INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT,
            resourceType: ResourceType.Deployments,
            minPermittedActions: [Action.Create, Action.Update],
            requiresAuth: true,
          },
        },
        {
          path: "data-source/pvc/new",
          name: INFERENCE_ROUTE_NAMES.INFERENCE_PVC_NEW,
          component: () => import("@/views/data-source/pvc/pvc-new.vue"),
          meta: {
            pageTitle: "New data source",
            fullWidth: true,
            requestToLeave: true,
            backPageName: INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT,
            resourceType: ResourceType.Deployments,
            minPermittedActions: [Action.Create, Action.Update],
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "model",
      name: INFERENCE_ROUTE_NAMES.INFERENCE_MODEL_EDIT,
      component: () => import("@/views/deployment/creation/inference-model-based.vue"),
      meta: {
        pageTitle: "New inference",
        fullWidth: true,
        requestToLeave: true,
        resourceType: ResourceType.Deployments,
        minPermittedActions: [Action.Create, Action.Update],
        requiresAuth: true,
      },
      beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
        const settingStore = useSettingStore();
        if (settingStore.isFeatureEnabled(SettingKeys.EnableModelCatalog)) {
          next();
        } else {
          next({ name: INFERENCE_ROUTE_NAMES.INFERENCE_NEW });
        }
      },
    },
  ],
};
