<template>
  <q-btn @click="onLeave" :icon="supportBack ? 'far fa-arrow-left' : 'far fa-xmark'" size="12px" flat round>
    <q-tooltip class="tooltip-dark" anchor="center right" self="center left" :offset="[10, 10]">
      Leave this page
    </q-tooltip>
  </q-btn>
</template>
<script lang="ts">
import { defineComponent } from "vue";

// stores
import { useAppStore } from "@/stores/app.store";
import { useDeploymentStore } from "@/stores/deployment.store";

// services
import { requestToLeave } from "@/services/infra/router.service/router.service";

// routes
import { INFERENCE_ROUTE_NAMES } from "@/router/deployment.routes/deployment.routes.names";
import { MODEL_SPEC_NAMES } from "@/router/model-spec.routes/model-spec.routes.names";

export default defineComponent({
  name: "back-or-close-btn",
  data() {
    return {
      appStore: useAppStore(),
      deploymentStore: useDeploymentStore(),
    };
  },
  computed: {
    supportBack(): boolean {
      return !!this.$route.meta.supportBack;
    },
    askToLeave(): boolean {
      return !!this.$route.meta.requestToLeave;
    },
  },
  methods: {
    async onLeave() {
      if (!this.askToLeave || this.appStore.isFallback) {
        this.redirect();
        return;
      }
      const confirm = await requestToLeave();
      if (confirm) this.redirect();
    },

    redirect(): void {
      const currentRoute: string | undefined = this.$route.name?.toString();
      const inferenceRoutes: string[] = [
        INFERENCE_ROUTE_NAMES.INFERENCE_NEW,
        INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT,
        INFERENCE_ROUTE_NAMES.INFERENCE_MODEL_EDIT,
        MODEL_SPEC_NAMES.MODEL_SPEC_NEW,
      ];
      if (currentRoute && inferenceRoutes.includes(currentRoute)) {
        this.$router.push({ name: this.deploymentStore.originRouteName });
      } else if (this.$route?.meta?.backPageName) {
        if (this.$route?.query?.previousRoute) this.$router.push({ name: this.$route.query.previousRoute as string });
        else this.$router.push({ name: this.$route?.meta?.backPageName as string });
      } else {
        this.$router.push(this.appStore.lastBackPath || "/");
      }
    },
  },
});
</script>

<style scoped lang="scss"></style>
