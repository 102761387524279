import { dateUtil, TimeUnit } from "@/utils/date.util";

export enum PREDEFINED_DATES {
  CUSTOM = "Custom",
  TODAY = "Today",
  YESTERDAY = "Yesterday",
  LAST_7_DAYS = "Last 7 days",
  LAST_14_DAYS = "Last 14 days",
  LAST_30_DAYS = "Last 30 days",
}

export enum PREDEFINED_HOURS {
  LAST_HOUR = "Last hour",
  LAST_3_HOURS = "Last 3 hours",
  LAST_6_HOURS = "Last 6 hours",
  LAST_24_HOURS = "Last 24 hours",
}

export enum PREDEFINED_MINUTES {
  LAST_15_MINUTES = "Last 15 minutes",
}

export interface PredefinedOption {
  label: string;
  daysCount?: number;
  hoursCount?: number;
  minutesCount?: number;
  endDate?: Date;
  disabled?: boolean;
}

export const defaultPredefinedOptions: Array<PredefinedOption> = [
  { label: PREDEFINED_DATES.TODAY, hoursCount: 0 },
  {
    label: PREDEFINED_DATES.YESTERDAY,
    daysCount: 1,
    endDate: dateUtil.adjustDateBy(TimeUnit.day, new Date(), -1),
  },
  { label: PREDEFINED_DATES.LAST_7_DAYS, daysCount: 7 },
  { label: PREDEFINED_DATES.LAST_30_DAYS, daysCount: 30 },
];

export const hoursGranularityPredefinedOptions: Array<PredefinedOption> = [
  { label: PREDEFINED_HOURS.LAST_HOUR, hoursCount: 1 },
  { label: PREDEFINED_HOURS.LAST_6_HOURS, hoursCount: 6 },
  ...defaultPredefinedOptions,
];
export const minutesGranularityPredefinedOptions: Array<PredefinedOption> = [
  { label: PREDEFINED_MINUTES.LAST_15_MINUTES, minutesCount: 15 },
];
export const extendedDefaultPredefinedOptions: Array<PredefinedOption> = [
  { label: PREDEFINED_DATES.TODAY, daysCount: 0 },
  {
    label: PREDEFINED_DATES.YESTERDAY,
    daysCount: 1,
    endDate: dateUtil.adjustDateBy(TimeUnit.day, new Date(), -1),
  },
  { label: PREDEFINED_DATES.LAST_7_DAYS, daysCount: 7 },
  { label: PREDEFINED_DATES.LAST_14_DAYS, daysCount: 14 },
  { label: PREDEFINED_DATES.LAST_30_DAYS, daysCount: 30 },
];

export const extendedHoursPredefinedOptions: Array<PredefinedOption> = [
  { label: PREDEFINED_HOURS.LAST_3_HOURS, hoursCount: 3 },
  { label: PREDEFINED_HOURS.LAST_6_HOURS, hoursCount: 6 },
  { label: PREDEFINED_HOURS.LAST_24_HOURS, hoursCount: 24 },
  ...extendedDefaultPredefinedOptions,
];

export interface DateTimeFormatOptions {
  includeSeconds?: boolean;
  includeMilliseconds?: boolean;
}
