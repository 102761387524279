import type { INodeWorkload } from "@/models/node-workload.model";
import type { INodeGpu } from "@/models/node-gpu.model";

import type { NodeStatusConditionDetails } from "@/swagger-models/cluster-service-client";

export interface INode {
  id: string;
  node: string;
  nodeStatus?: string;
  utilization: string;
  gpuType?: string;
  totalGpus?: string;
  allocatedGpus: string;
  usedGpus: string;
  totalCpus: string;
  allocatedCpus: string;
  usedCpus: string;
  totalGpuMemory: string;
  usedGpuMemory: string;
  totalCpuMemory: string;
  allocatedMemory: string;
  usedCpuMemory: string;
  swapCpuMemory: string;
  nodePool: string;
  workloads?: Array<INodeWorkload>;
  gpuDevices?: Array<INodeGpu>;
  conditions?: Array<NodeStatusConditionDetails>;
}

export enum EMetricsType {
  DEFAULT = "Default",
  ADVANCED = "Advanced",
}

export const NodeConditionMap: Record<string, NodeStatusConditionDetails> = {
  ["DiskPressure"]: {
    type: "DiskPressure",
    reason: "Disk pressure",
    message: "Disk pressure: Disk capacity is low.",
  },
  ["MemoryPressure"]: {
    type: "MemoryPressure",
    reason: "Memory pressure",
    message: "Memory pressure: Node memory is low.",
  },
  ["PidPressure"]: {
    type: "PidPressure",
    reason: "PID pressure",
    message: "PID pressure: Too many processes on the node.",
  },
  ["NetworkUnavailable"]: {
    type: "NetworkUnavailable",
    reason: "Network unavailable",
    message: "Network unavailable: Network is not configured correctly for the node.",
  },
  ["SchedulingDisabled"]: {
    type: "SchedulingDisabled",
    reason: "Scheduling disabled",
    message:
      "Scheduling disabled: Node might be cordoned and marked as unavailable to the scheduler. Uncordon it to make it available.",
  },
  ["UndrainedMigratedNode"]: {
    type: "UndrainedMigratedNode",
    reason: "Undrained migrated node",
    message: "Undrained migrated node: For the node to be ready again, evict all pods.",
  },
  ["MissingNvidiaContainerToolkit"]: {
    type: "MissingNvidiaContainerToolkit",
    reason: "Missing NVIDIA Container Toolkit",
    message: "Missing NVIDIA container toolkit: For the node to be ready again, install NVIDIA container toolkit.",
  },
  ["MissingNvidiaDcgmExporter"]: {
    type: "MissingNvidiaDcgmExporter",
    reason: "Missing nvidia DCGM Exporter",
    message: "Missing NVIDIA DCGM: Some metrics are missing. For the full list, install the NVIDIA DCGM.",
  },
  ["NodeNotReady"]: {
    type: "NodeNotReady",
    reason: "Node not ready",
    message: "Node not ready.",
  },
};

export interface INodeWorkloadsModalOptions {
  header: string;
  workloads: INodeWorkload[];
}

export interface INodeGpusModalOptions {
  header: string;
  gpuDevices: INodeGpu[];
}
