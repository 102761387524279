import { defineStore } from "pinia";

import { accessRuleService } from "@/services/control-plane/rbac/access-rule.service/access-rule.service";

import { useClusterStore } from "./cluster.store";

import type { IDepartment, ILoadDepartmentsOptions } from "@/models/department.model";
import { filterService } from "@/services/filter.service/filter.service";
import { allDepartmentColumns } from "@/table-models/department.table-model";
import type { IFilterBy } from "@/models/filter.model";
import type { ICluster } from "@/models/cluster.model";
import { departmentService } from "@/services/control-plane/department.service/department.service";
import { enrichScopeEntityWithAccessRules } from "@/utils/rbac.util/access-rule.util/access-rule.util";
import { usePermissionStore } from "@/stores/permissions.store";
import type { WorkspacePolicy, PolicyType } from "@/swagger-models/policy-service-client";
import { Action, ResourceType, ScopeType, SubjectType } from "@/swagger-models/authorization-client";
import { useAuthStore } from "./auth.store";

export const useDepartmentStore = defineStore("Department", {
  state: () => ({
    departments: [] as Array<IDepartment>,
    selectedDepartment: null as IDepartment | null,
    lastCreatedDepartmentId: null as null | number,
  }),
  getters: {
    lastCreatedDepartment(): IDepartment | null {
      if (!this.lastCreatedDepartmentId) return null;
      return this.departments.find((department: IDepartment) => department.id === this.lastCreatedDepartmentId) || null;
    },
    departmentList(): Array<IDepartment> {
      return this.departments.filter((department: IDepartment) => department.id !== this.lastCreatedDepartmentId);
    },
    departmentById:
      (state) =>
      (departmentId: number): IDepartment | undefined => {
        return state.departments.find((department: IDepartment) => departmentId === department.id);
      },
  },
  actions: {
    async loadPolicy(workloadType: PolicyType, departmentId: string): Promise<WorkspacePolicy> {
      return departmentService.loadPolicy(workloadType, departmentId);
    },
    async loadDepartments(
      options?: ILoadDepartmentsOptions,
      filterBy: IFilterBy = {},
      forceLoading = false,
    ): Promise<Array<IDepartment>> {
      const { withMetrics = false, withAccessRules = true } = options || {};

      const clustersStore = useClusterStore();
      const cluster: ICluster = clustersStore.currentCluster;
      let departments: IDepartment[] = await departmentService.list(cluster?.uuid, filterBy, forceLoading).catch(() => {
        return [];
      });

      departments = filterService.filterListByTableFilters(departments, filterBy, allDepartmentColumns);

      if (withAccessRules && usePermissionStore().hasPermission(ResourceType.AccessRules, Action.Read)) {
        const scopeAccessRules = await accessRuleService.getAccessRules({
          scopeType: ScopeType.Department,
        });
        this.departments = enrichScopeEntityWithAccessRules(departments, scopeAccessRules);
      } else {
        this.departments = departments;
      }
      if (withMetrics) {
        //todo use getDepartmentsMetrics from resources.service with types
        this.departments = await departmentService.enrichDepartmentsWithResourcesMetrics(
          this.departments,
          clustersStore.currentCluster.uuid,
        );
      }

      return this.departmentList;
    },
    updateDepartmentSubjectType(departmentId: number, subjectType: SubjectType): void {
      this.departments = this.departments.map((department) => {
        if (department.id !== departmentId) return department;
        return { ...department, roles: [subjectType, ...(department.roles || [])] };
      });
    },
    deleteDepartmentSubjectType(departmentId: number, subjectTypeIndex: number): void {
      const departmentIndex = this.departments.findIndex((department: IDepartment) => department.id === departmentId);
      this.departments[departmentIndex].roles?.splice(subjectTypeIndex, 1);
    },
    setSelectedDepartment(department: IDepartment | null): void {
      this.selectedDepartment = department;
    },
    async removeDepartment(departmentId: number): Promise<void> {
      const clustersStore = useClusterStore();
      const clusterUuid: string = clustersStore.currentCluster.uuid;
      await departmentService.remove(departmentId, clusterUuid);
      await useAuthStore().loadUserOrgUnits();
    },
    async save(department: IDepartment): Promise<IDepartment> {
      const clustersStore = useClusterStore();
      const clusterUuid: string = clustersStore.currentCluster.uuid;
      const savedDepartment = await departmentService.save(department, clusterUuid);
      if (department.id) {
        this.departments.map((d) => (d.id === department.id ? savedDepartment : d));
      } else if (savedDepartment) {
        if (savedDepartment.id) {
          this.setLastCreatedDepartmentId(savedDepartment.id);
        }
        this.departments.push(savedDepartment);
      }
      await useAuthStore().loadUserOrgUnits();
      return savedDepartment;
    },
    setLastCreatedDepartmentId(departmentId: number): void {
      this.lastCreatedDepartmentId = departmentId;
    },
    removeLastCreatedDepartmentId(): void {
      this.lastCreatedDepartmentId = null;
    },
    getNamespaceByDepartmentId(departmentId: string | null): string {
      if (!departmentId) return "";
      return `runai-${departmentId}`; // TODO: return the correct string structure when backend is ready
    },
  },
});
