import type { IStatusColOptions } from "./table.model";
import {
  type Pod as WorkloadServicePod,
  Phase,
  WorkloadSortFilterFields,
  type Connection,
} from "@/swagger-models/workloads-service-client";
import type { SpecificRunConnectionInfo, AssetIdAndKind, Distributed } from "@/swagger-models/assets-service-client";

import type { IAutoScaleData } from "@/components/section/compute-resource-section";
import type { IItemizedListItem } from "@/components/common/runai-itemized-list";
import type { IUIVolume } from "@/models/data-source.model";
import { INFERENCE_ROUTE_NAMES } from "@/router/deployment.routes/deployment.routes.names";
import { WORKSPACE_ROUTE_NAMES } from "@/router/workspace.routes/workspace.routes.names";
import { TRAINING_ROUTE_NAMES } from "@/router/training.routes/training.routes.names";
import type { RouteRecordName } from "vue-router";
import type { DrilldownEventObject, Point, SeriesOptionsRegistry } from "highcharts";

export type { WorkloadServicePod };

export const workloadAssetsRoutes: RouteRecordName[] = [
  TRAINING_ROUTE_NAMES.TRAINING_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_COMPUTE_RESOURCE_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_ENVIRONMENT_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT,
  TRAINING_ROUTE_NAMES.TRAINING_NFS_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_S3_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_GIT_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_PVC_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_HOST_PATH_NEW,
  TRAINING_ROUTE_NAMES.TRAINING_CONFIG_MAP_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_COMPUTE_RESOURCE_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_ENVIRONMENT_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_NFS_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_S3_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_PVC_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_GIT_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_HOST_PATH_NEW,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_CONFIG_MAP_NEW,
  INFERENCE_ROUTE_NAMES.INFERENCE_NEW,
  INFERENCE_ROUTE_NAMES.INFERENCE_COMPUTE_RESOURCE_NEW,
  INFERENCE_ROUTE_NAMES.INFERENCE_ENVIRONMENT_NEW,
  INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT,
  INFERENCE_ROUTE_NAMES.INFERENCE_NFS_NEW,
  INFERENCE_ROUTE_NAMES.INFERENCE_S3_NEW,
  INFERENCE_ROUTE_NAMES.INFERENCE_PVC_NEW,
  INFERENCE_ROUTE_NAMES.INFERENCE_GIT_NEW,
  INFERENCE_ROUTE_NAMES.INFERENCE_HOST_PATH_NEW,
];

export type TWorkloadMetricValue = [number, string];
interface IWorkloadMetadataAnnotations {
  workspaceId: string;
}
export interface IGetWorkloadPods200Response {
  displayRecords: number;
  pods: WorkloadServicePod[];
}
export interface IWorkloadMetadata {
  name: string;
  namespace: string;
  creationTimestamp?: null;
  annotations: IWorkloadMetadataAnnotations;
}

export interface IWorkloadSpec {
  environment: {
    items: Record<string, { value: string }>;
  };
  name: { value: string };
  image: { value: string };
  username: { value: string };
  command?: { value: string };
  arguments?: { value: string };
  volumes?: {
    items: Record<string, unknown>;
  };
  cpu?: { value: string };
  gpu?: { value: string };
  memory?: { value: string };
  migProfile?: { value: string };
  nodeType?: { value: string };
  gitSync?: {
    items: Record<string, unknown>;
  };
  largeShm: {
    value: boolean;
  };
  imagePullPolicy: {
    value: string;
  };
  annotations: {
    items: Record<string, unknown>;
  };
  preemptible?: { value: string };
}

export interface IWorkload {
  apiVersion: string;
  kind: string;
  metadata: IWorkloadMetadata;
  spec: IWorkloadSpec;
  masterSpec?: IWorkloadSpec;
}

export interface IWorkloadCreate {
  metadata: IWorkloadMetadata;
  spec: IWorkloadSpec;
  status?: Record<string, unknown>;
  masterSpec?: IWorkloadSpec;
}

export interface IWorkloadResponse {
  metadata: IWorkloadMetadata;
  spec: IWorkloadSpec;
  status?: Record<string, unknown>;
}

export interface IWorkloadDurationOption {
  value: string;
  label: string;
  duration: number | null;
}

export interface EWorkloadTelemetryPointMeta {
  entityId: string;
  groupByKey: EWorkloadTelemetryGroupBy;
}
//@ts-ignore
export interface DrillupEventWorkloadTelemetryObject extends DrillupEventObject {
  seriesOptions: {
    data: {
      drilldown: boolean;
      meta: EWorkloadTelemetryPointMeta;
      name: string;
      y: number;
    }[];
  };
}
//@ts-ignore
export interface DrilldownEventWorkloadTelemetryObject extends DrilldownEventObject {
  point: { meta: EWorkloadTelemetryPointMeta };
  seriesOptions: {
    data: {
      drilldown: boolean;

      name: string;
      y: number;
    }[];
  };
}
export interface ETelemetrySeriesPoint extends Point {
  meta: EWorkloadTelemetryPointMeta;
}
export interface ETelemetryDrilldownEventObject extends DrilldownEventObject {
  point: ETelemetrySeriesPoint;
  drilldown: SeriesOptionsRegistry;
}

export enum EIdleWorkloadMaxDuration {
  training = "trainingJobMaxIdleDurationSecs",
  interactivePreemptible = "interactivePreemptibleJobMaxIdleDurationSecs",
  interactive = "interactiveJobMaxIdleDurationSecs",
}

export interface IWorkloadListModal {
  name: string;
  type: string;
  status: IStatusColOptions;
}

export enum EWorkloadName {
  Workspace = "workspace",
  Training = "training",
  Inference = "inference",
}

export enum EWorkloadType {
  Workspace = "Workspace",
  Training = "Training",
  Inference = "Inference",
  Distributed = "Distributed",
}

export enum EWorkloadFormType {
  Workspace = "workspace",
  Training = "training",
  Template = "template",
  Deployment = "deployment",
  Inference = "inference",
}

export enum EExternalWorkloadType {
  SeldonDeployment = "SeldonDeployment",
  VirtualMachineInstance = "VirtualMachineInstance",
  TFJob = "TFJob",
  PyTorchJob = "PyTorchJob",
  XGBoostJob = "XGBoostJob",
  MPIJob = "MPIJob",
  Notebook = "Notebook",
  Job = "Job",
  StatefulSet = "StatefulSet",
  ReplicaSet = "ReplicaSet",
  Pod = "Pod",
  AmlJob = "AmlJob",
  Service = "Service",
  CronJob = "CronJob",
  DevWorkspace = "DevWorkspace",
  RayCluster = "RayCluster",
  RayJob = "RayJob",
  RayService = "RayService",
  TaskRun = "TaskRun",
  PipelineRun = "PipelineRun",
  Workflow = "Workflow",
  ScheduledWorkflow = "ScheduledWorkflow",
  NVIDIANIM = "NVIDIA-NIM",
}

export interface IUIDistributedMaster {
  assets: IUIWorkloadAssets;
  specificEnv: IUIWorkloadSpecificEnv;
}

export interface IUIDistributed extends Omit<Distributed, "master"> {
  master?: IUIDistributedMaster | null;
}

export interface IUIWorkloadCreation {
  name: string;
  projectId: number;
  namespace: string;
  clusterId: string;
  assets: IUIWorkloadAssets;
  specificEnv: IUIWorkloadSpecificEnv;
  distributed?: IUIDistributed | null;
  enableEditingMaster?: boolean;
}

export interface IUIWorkloadModelCreation extends IUIWorkloadCreation {
  modelId: string;
}

export interface IUIWorkloadAssets {
  environment: string | null;
  compute: string | null;
  datasources?: Array<AssetIdAndKind>;
  uiVolumes?: Array<IUIVolume>;
}

export interface IUIWorkloadSpecificEnv {
  command?: string | null;
  args?: string | null;
  environmentVariables?: Array<IItemizedListItem>;
  allowOverQuota?: boolean | null;
  connections?: Array<SpecificRunConnectionInfo> | null;
  runAsGid?: number | null;
  runAsUid?: number | null;
  supplementalGroups?: string | null;
  nodeType?: string | null;
  nodePools?: Array<string> | null;
  annotations?: Array<IItemizedListItem>;
  labels?: Array<IItemizedListItem>;
  autoDeletionTimeAfterCompletionSeconds?: number | null;
  autoScaleData?: IAutoScaleData;
}

export interface IWorkloadMeta {
  name: string;
  namespace: string;
  clusterId: string;
  projectId: number;
}

export enum EWorkloadEntity {
  workspace = "workspace",
  training = "training",
  workload = "workload",
  deployment = "deployment",
}

export const WORKLOADS_REFRESH_INTERVAL = 5000;
export const WORKLOADS_COUNT_REFRESH_INTERVAL = 60000; //one minute
export enum EWorkloadAction {
  activate = "activate",
  run = "run",
  stop = "stop",
  connect = "connect",
  delete = "delete",
  clone = "clone",
}

export interface JobMetricsData {
  gpuUtilization: Array<Array<number>>;
  gpuMemory: Array<Array<number>>;
  cpuUtilization: Array<Array<number>>;
  cpuMemory: Array<Array<number>>;
}

export enum EWorkloadNodeArchitecture {
  Distributed = "Distributed",
  Standard = "Standard",
}

export const ExtendedPhase = {
  ...Phase,
  Succeeded: "Succeeded",
} as const;

export type ExtendedPhase = typeof ExtendedPhase[keyof typeof ExtendedPhase];

export const WorkloadPhaseMap: Record<ExtendedPhase, IStatusColOptions> = {
  [ExtendedPhase.Creating]: {
    status: "Creating...",
    displayAnimation: true,
  },
  [ExtendedPhase.Initializing]: {
    status: "Initializing",
    displayAnimation: false,
  },
  [ExtendedPhase.Updating]: {
    status: "Updating...",
    displayAnimation: true,
  },
  [ExtendedPhase.Pending]: {
    status: "Pending",
    color: "warning",
    displayAnimation: false,
  },
  [ExtendedPhase.Running]: {
    status: "Running",
    displayAnimation: false,
    color: "success",
  },
  [ExtendedPhase.Stopped]: {
    status: "Stopped",
    displayAnimation: false,
  },
  [ExtendedPhase.Degraded]: {
    status: "Running with issues",
    displayAnimation: false,
  },
  [ExtendedPhase.Failed]: {
    status: "Failed",
    color: "negative",
    displayAnimation: false,
  },
  [ExtendedPhase.Completed]: {
    status: "Completed",
    displayAnimation: false,
  },
  [ExtendedPhase.Deleting]: {
    status: "Deleting...",
    displayAnimation: true,
    filterKey: "status",
  },
  [ExtendedPhase.Unknown]: {
    status: "Unknown",
    displayAnimation: false,
  },
  [ExtendedPhase.Stopping]: {
    status: "Stopping...",
    displayAnimation: true,
  },
  [ExtendedPhase.Terminating]: {
    status: "Terminating...",
    displayAnimation: true,
  },
  [ExtendedPhase.Succeeded]: {
    status: "Succeeded",
    color: "success",
    displayAnimation: false,
  },
  [ExtendedPhase.Resuming]: {
    status: "Resuming...",
    displayAnimation: true,
  },
};

export enum EWorkloadErrorMessage {
  NotRunaiWorkloadMessage = "This action is not supported for the selected workload. To perform the action, go to the application you used to create the workload.",
  FailedToLoadWorkloads = "Failed to load workloads",
  FailedToLoadWorkloadsCount = "Failed to load workloads count",
  FailedToLoadPads = "Failed to load pods",
  FailedToRunWorkload = "Failed to start workload",
  FailedToDeleteWorkloads = "Failed to load workloads",
  FailedToStopWorkloads = "Failed to stop workload",
  NoDeletePermission = "You don't have permission to delete this workload.",
  DeleteWorkloadIsNotSupported = "Delete operation is not supported for this workload type",
  WorkloadIsDeleting = "The selected workload is already deleted or in the process of being deleted",
}

export interface IWorkloadPodsModalOptions {
  pods: WorkloadServicePod[];
  loading: boolean;
  header: string;
}
export interface IWorkloadConnectionsModalOptions {
  connections: Connection[];
  header: string;
  workloadDetails?: {
    currentUser: string;
    isRunning: boolean;
    name: string;
    type: string;
    projectName: string;
  };
}

//metric
export interface IWorkloadMetricRangeParams {
  start: number;
  end: number;
  step: number;
}

export interface IWorkloadListModalOptions {
  entityFilter: string;
  //in order to support the table to pick the correct filter need to supply both filterName and entityName
  filterName?: WorkloadSortFilterFields;
  entityName: string;
  entityType: EWorkloadModalEntity;
}

export enum EWorkloadModalEntity {
  Project = "Project",
  Department = "Department",
}

export enum EWorkloadTelemetryGroupBy {
  ClusterId = "ClusterId",
  DepartmentId = "DepartmentId",
  ProjectId = "ProjectId",
  Type = "Type",
  CurrentNodepools = "CurrentNodepools",
  Phase = "Phase",
}
