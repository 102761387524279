<template>
  <chart-widget-wrapper
    :options="wrapperOptions"
    @link-clicked="redirectToWorkloads"
    @export-csv="exportCsv"
    :loading="displayLoading"
    :error="error"
    :empty="isChartEmpty"
  >
    <highcharts v-if="chartOptions" :options="chartOptions" ref="chart" key="resource-allocation-by-workload-chart" />
  </chart-widget-wrapper>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
//routes
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes";
//store
import { useClusterStore } from "@/stores/cluster.store";
//models
import { EWidgetEntity, type IWidgetWrapperOptions, UNKNOWN_COLOR } from "@/models/chart.model";
//cmps
import { Chart } from "highcharts-vue";
import { ChartWidgetWrapper } from "@/components/dashboard-v2/widgets/common/widget-wrapper/chart-widget-wrapper";
//Highcharts
import type {
  BreadcrumbOptions,
  BreadcrumbsFormatterCallbackFunction,
  Options as HighchartsOptions,
  SeriesOptionsType,
} from "highcharts";
import type Highcharts from "highcharts";

//service
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import { type TelemetryResponseValuesInner, WorkloadTelemetryType } from "@/swagger-models/workloads-service-client";
//model
import {
  type DrillupEventWorkloadTelemetryObject,
  EExternalWorkloadType,
  type ETelemetryDrilldownEventObject,
  EWorkloadTelemetryGroupBy,
  EWorkloadType,
} from "@/models/workload.model";
import { HttpErrorResponse } from "@/models/http-response.model";
//util
import { widgetUtil } from "@/utils/widget.util";
import { chartUtil } from "@/utils/chart.util";
import type { IFilterModel } from "@/models/filter.model";
import { dashboardUtil } from "@/utils/dashboard.util";
import { useSettingStore } from "@/stores/setting.store";
import { SettingKeys } from "@/models/setting.model";

const colorsMap = chartUtil.assignColorsToKeys<string>([
  ...Object.keys(EWorkloadType),
  ...Object.keys(EExternalWorkloadType),
  "Unknown",
]);

export default defineComponent({
  name: "resource-allocation-by-workload-widget",
  components: { ChartWidgetWrapper, Highcharts: Chart },
  props: {
    filterBy: {
      type: Array as PropType<IFilterModel[]>,
      required: false,
    },
  },
  data() {
    return {
      isDataLoading: false as boolean,
      displayLoading: true as boolean,
      chartOptions: null as null | HighchartsOptions,
      clusterStore: useClusterStore(),
      error: false as boolean,
      isChartEmpty: false as boolean,
      wrapperOptions: {
        title: "Resource allocation by workload type",
        timeFrame: "Now",
        linkText: "All workloads",
        entityType: EWidgetEntity.Workload,
      } as IWidgetWrapperOptions,
      groupBy: EWorkloadTelemetryGroupBy.ClusterId as EWorkloadTelemetryGroupBy,
    };
  },
  created() {
    this.loadChartOptions();
    this.loadInitialChartData();
  },
  mounted() {
    if (this.chartOptions !== null) {
      //@ts-ignore
      this.chartOptions.chart.events.drilldown = this.drilldown.bind(this);
      //@ts-ignore
      this.chartOptions.chart.events.drillup = this.drillup.bind(this);
    }
  },
  computed: {
    isDepartmentEnabled(): boolean {
      return useSettingStore().isFeatureEnabled(SettingKeys.DepartmentsUse);
    },
    clusterId(): string {
      return this.clusterStore.currentClusterId;
    },
    clusterName(): string {
      return this.clusterStore.currentClusterName;
    },
    chart(): Highcharts.Chart {
      const chartRef = this.$refs?.chart as Chart;
      return chartRef?.chart as Highcharts.Chart;
    },
    nodePoolFilter(): string | undefined {
      const nodePoolFilter = this.filterBy?.find((filter: IFilterModel) => filter.field === "nodepool");
      if (nodePoolFilter) {
        return nodePoolFilter.term;
      }
      return undefined;
    },
  },
  methods: {
    loadChartOptions(): void {
      this.chartOptions = chartUtil.getBasicWidgetChartOptions({
        yAxisTitle: "GPU devices",
        type: "column",
        height: 240,
        drilldownFormatter: this.formatBreadcrumbs as unknown as BreadcrumbsFormatterCallbackFunction,
      });
      this.loadTooltipFormatter();
    },
    loadTooltipFormatter(): void {
      if (this.chartOptions?.tooltip) {
        this.chartOptions.tooltip.pointFormatter = function () {
          return `<span style="color:${this.color}">●</span>  Workload type: <span class="text-bold">${this.series.name}</span><br>&nbsp;&nbsp;&nbsp; GPU allocation: <span class="text-bold">${this.y}</span>`;
        };
      }
    },
    async loadInitialChartData(): Promise<void> {
      if (this.chartOptions === null) return;
      try {
        const res: TelemetryResponseValuesInner[] = await this.getWorkloadsTelemetry(
          EWorkloadTelemetryGroupBy.ClusterId,
        );
        const enrichedRes: TelemetryResponseValuesInner[] = widgetUtil.enrichTelemetryResponseByKeyValue(
          res,
          EWorkloadTelemetryGroupBy.ClusterId,
          this.clusterName,
          "name",
        );
        if (enrichedRes.length === 0) {
          this.isChartEmpty = true;
        } else {
          this.isChartEmpty = false;
          this.chartOptions.series = this.aggregateTelemetryGroupedDataForHighcharts(
            enrichedRes,
            EWorkloadTelemetryGroupBy.ClusterId,
            true,
          );
        }

        this.error = false;
        this.groupBy = EWorkloadTelemetryGroupBy.ClusterId;
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.displayLoading = false;
      }
    },
    async drillup(e: DrillupEventWorkloadTelemetryObject): Promise<void> {
      if (this.isDataLoading || !this.chartOptions) return;
      const { groupByKey } = e.seriesOptions.data[0].meta;
      if (groupByKey === EWorkloadTelemetryGroupBy.ClusterId) {
        await this.loadInitialChartData();
        return;
      }
      try {
        this.isDataLoading = true;
        const res = await this.getWorkloadsTelemetry(groupByKey);
        this.chartOptions.series = this.aggregateTelemetryGroupedDataForHighcharts(res, groupByKey, true);

        this.error = false;
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.isDataLoading = false;
      }
    },
    async drilldown(e: ETelemetryDrilldownEventObject): Promise<void> {
      if (this.isDataLoading || !(!e.seriesOptions && this.chartOptions !== null)) return;
      const { departmentIdFilter, includeDrilldown, groupByKey } = widgetUtil.getWorkloadTelemetryDrilldownOptions(
        e.point.meta,
        this.isDepartmentEnabled,
      );

      try {
        this.isDataLoading = true;
        const res = await this.getWorkloadsTelemetry(groupByKey, departmentIdFilter);
        this.groupBy = EWorkloadTelemetryGroupBy.CurrentNodepools;
        const series: SeriesOptionsType[] = this.aggregateTelemetryGroupedDataForHighcharts(
          res,
          groupByKey,
          includeDrilldown,
        );

        // @ts-ignore
        series.forEach((type: SeriesOptionsType) => this.chart.addSingleSeriesAsDrilldown(e.point, type));
        // @ts-ignore
        this.chart.applyDrilldown();
        this.error = false;
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.isDataLoading = false;
      }
    },
    formatBreadcrumbs(breadcrumbOptions: BreadcrumbOptions): string {
      if (breadcrumbOptions.level === 0) return this.clusterName;
      if (breadcrumbOptions.level === 1 && this.isDepartmentEnabled) {
        return "Departments";
      }
      return "Projects";
    },
    async getWorkloadsTelemetry(
      groupByKey: EWorkloadTelemetryGroupBy,
      departmentId?: string,
    ): Promise<TelemetryResponseValuesInner[]> {
      const res = await workloadService.getWorkloadsTelemetry(
        WorkloadTelemetryType.GpuAllocation,
        this.clusterId,
        this.nodePoolFilter,
        departmentId,
        [groupByKey, EWorkloadTelemetryGroupBy.Type],
      );

      return res.values;
    },
    aggregateTelemetryGroupedDataForHighcharts(
      values: TelemetryResponseValuesInner[],
      groupByKey: EWorkloadTelemetryGroupBy,
      includeDrilldown = false,
    ): SeriesOptionsType[] {
      const series: SeriesOptionsType[] = widgetUtil.aggregateTelemetryGroupedDataForHighcharts(
        values,
        [groupByKey, EWorkloadTelemetryGroupBy.Type],
        includeDrilldown,
      );
      return this.addColorsByWorkloadType(series);
    },
    addColorsByWorkloadType(series: SeriesOptionsType[]): SeriesOptionsType[] {
      return series.map((series: SeriesOptionsType) => {
        const workloadType: string | undefined = series.name;
        if (!workloadType) return series;
        if (colorsMap.has(workloadType)) {
          series.color = colorsMap.get(workloadType);
        } else {
          series.color = UNKNOWN_COLOR;
        }
        return series;
      });
    },
    async exportCsv() {
      try {
        await workloadService.getWorkloadsTelemetryCsv(
          WorkloadTelemetryType.GpuAllocation,
          this.clusterId,
          this.nodePoolFilter,
          undefined,
          [this.groupBy, EWorkloadTelemetryGroupBy.Type],
        );
      } catch (error: unknown) {
        this.$q.notify(dashboardUtil.getCsvErrorMessage());
        this.handleError(error);
      }
    },
    handleError(error: unknown): void {
      this.error = true;
      if (error instanceof HttpErrorResponse) {
        console.error(error.message);
      } else {
        console.error("Error fetching data", error);
      }
    },
    redirectToWorkloads(): void {
      this.$router.push({
        name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX,
      });
    },
  },
  watch: {
    filterBy: {
      handler(): void {
        this.displayLoading = true;
        this.loadInitialChartData();
      },
      deep: true,
    },
  },
});
</script>

<style scoped lang="scss"></style>
