import { researcherService } from "@/services/cluster/researcher.service/researcher.service";
import { clusterApiService } from "@/services/cluster/cluster-api.service/cluster-api.service";
import { workloadServiceApi } from "@/services/infra/client-apis/workloads-service-api/workloads-service-api";
import { httpService } from "@/services/infra/https.service/http.service";
import { deploymentService } from "@/services/control-plane/deployment.service/deployment.service";
import { assetsServiceApi } from "@/services/infra/client-apis/assets-service-api/assets-service-api";

import type { AxiosResponse } from "axios";
import type { WorkloadMetricType, MetricsResponse } from "@/swagger-models/workloads-service-client";

import { API, RESEARCHER } from "@/common/api.constant";
import type {
  IWorkloadMetadata,
  IWorkloadSpec,
  IWorkload,
  IWorkloadResponse,
  IGetWorkloadPods200Response,
  IWorkloadMetricRangeParams,
  TWorkloadMetricValue,
  EWorkloadTelemetryGroupBy,
} from "@/models/workload.model";
import { EWorkloadType } from "@/models/workload.model";

import { HttpErrorResponse } from "@/models/http-response.model";
import type { IFilterBy, IPaginationFilter } from "@/models/filter.model";
import { controlPlaneService } from "@/services/control-plane/control-plane.service/control-plane.service";
import type {
  GetWorkloadHistory200Response,
  GetWorkloads200Response,
  TelemetryResponse,
  Workload,
  WorkloadPatchFields,
  WorkloadTelemetryType,
} from "@/swagger-models/workloads-service-client";
import { httpResponseService } from "@/services/infra/http-response.service/http-response.service";
import { prometheusService } from "@/services/control-plane/prometheus.service/prometheus.service";
import { Phase } from "@/swagger-models/workloads-service-client";
import { workloadLocalPhaseUpdater } from "@/services/cluster/workload.service/local-status-updater";
import { filterService } from "@/services/filter.service/filter.service";
import { isNewerVersion } from "@/utils/version.util";
import { MIN_INFERENCE_AUTO_DELETE } from "@/common/version.constant";
import { fileService, FileType } from "@/services/file.service/file.service";
export const workloadService = {
  createWorkload,
  deleteWorkload,
  list,
  stopWorkload,
  activate,
  getWorkloadPods,
  getWorkloadHistory,
  getAllWorkloadUtilizationMetrics,
  getWorkloadById,
  getWorkloadsCount,
  getWorkloads,
  handleFailedWorkloadClusterCreation,
  getWorkloadsTelemetry,
  getWorkloadsTelemetryCsv,
  countWorkloadsByName,
  getWorkloadMetrics,
};

const WORKLOADS_RESEARCHER_ENDPOINT = `${RESEARCHER.v1}/workload/proxy`;
const apiVersion = "run.ai/v2alpha1";
const WORKLOADS_SERVICE_ENDPOINT = `${API.v1}/workloads`;
const WORKLOADS_TELEMETRY_ENDPOINT = `${API.v1}/workloads/telemetry`;

// api calls
async function createWorkload(
  kind: string,
  metadata: IWorkloadMetadata,
  spec: IWorkloadSpec,
  masterSpec?: IWorkloadSpec,
): Promise<IWorkloadResponse> {
  const workload: IWorkload = {
    apiVersion,
    kind,
    metadata,
    spec,
    masterSpec,
  };

  try {
    const workloadResponse = await clusterApiService.post(
      `${WORKLOADS_RESEARCHER_ENDPOINT}/namespaces/${metadata.namespace}/${kind}`,
      workload,
    );
    return workloadResponse;
  } catch (error: unknown) {
    if (error instanceof HttpErrorResponse) {
      switch (error.statusCode) {
        case 401:
          error.message = "The API server is not configured correctly. Contact your administrator.";
          break;
        case 502:
        case 504:
          error.message =
            "There are issues with your connection to the cluster. Make sure you're using your organization's VPN.";
          break;
        default:
          error.message = "There are issues with your connection to the cluster. Contact your administrator.";
      }
    }
    throw error;
  }
}

async function getWorkloads(clusterUuid: string, filters?: IPaginationFilter): Promise<GetWorkloads200Response> {
  return await controlPlaneService.get(WORKLOADS_SERVICE_ENDPOINT, {
    ...filters,
    filterBy: [...(filters?.filterBy || []), `clusterId==${clusterUuid}`],
  });
}

async function list(clusterUuid: string, filterBy: IFilterBy = {}): Promise<Workload[]> {
  const filters: IPaginationFilter = filterService.mapFilterToPaginationParams(filterBy);
  const response: GetWorkloads200Response = await getWorkloads(clusterUuid, filters);
  return workloadLocalPhaseUpdater.updateWorkloadsByLocalPhases(response.workloads);
}

async function getWorkloadsCount(
  clusterUuid: string,
  filterBy?: Array<string>,
  deleted = false,
): Promise<{ count: number }> {
  const response = await workloadServiceApi.workloadsApi.countWorkloads(deleted, [
    ...(filterBy || []),
    `clusterId==${clusterUuid}`,
  ]);
  return response.data;
}
async function deleteWorkload(workload: Workload, project: string, clusterVersion: string): Promise<void> {
  workloadLocalPhaseUpdater.updateLocalPhases(workload, Phase.Deleting);
  if (workload.type === EWorkloadType.Inference) {
    await deploymentService.remove(workload.name, workload.namespace);

    if (!isNewerVersion(clusterVersion, MIN_INFERENCE_AUTO_DELETE)) {
      await assetsServiceApi.inferenceApi.deleteInferenceById(workload.id);
    }
  } else {
    await researcherService.deleteJob(workload.name, project, workload.type);
  }
}

async function stopWorkload(workload: Workload): Promise<void> {
  if (!workload.projectName) throw new Error("Can't stop workload. Job is missing.");
  const res = await researcherService.stopWorkload(workload.name as string, workload.projectName);
  const errorMessage: string | undefined = res.data[0]?.error ? res.data[0].error.details : undefined;
  if (errorMessage) {
    throw httpResponseService.getError(res.data[0]?.code, errorMessage);
  }
}

async function activate(workload: Workload): Promise<void> {
  const res = await researcherService.activateWorkload(workload.name as string, workload.projectName);
  workloadLocalPhaseUpdater.updateLocalPhases(workload, Phase.Resuming);
  const errorMessage: string | undefined = res.data[0]?.error ? res.data[0].error.details : undefined;
  if (errorMessage) {
    throw httpResponseService.getError(res.data[0]?.code, errorMessage);
  }
}

async function getWorkloadPods(workloadId: string, clusterUuid: string): Promise<IGetWorkloadPods200Response> {
  return controlPlaneService.get(`${WORKLOADS_SERVICE_ENDPOINT}/${workloadId}/pods?clusterId=${clusterUuid}`);
}

async function getWorkloadById(workloadId: string): Promise<Workload> {
  return controlPlaneService.get(`${WORKLOADS_SERVICE_ENDPOINT}/${workloadId}`);
}

async function patchWorkloadById(workloadId: string, patchFields: WorkloadPatchFields): Promise<Workload> {
  return controlPlaneService.patch(`${WORKLOADS_SERVICE_ENDPOINT}/${workloadId}`, patchFields);
}

async function getWorkloadHistory(workloadId: string, clusterUuid: string): Promise<GetWorkloadHistory200Response> {
  return controlPlaneService.get(`${WORKLOADS_SERVICE_ENDPOINT}/${workloadId}/history?clusterId=${clusterUuid}`);
}

async function countWorkloadsByName(name: string, clusterId: string, projectId?: number | null): Promise<number> {
  try {
    let filterBy = `name==${name},clusterId==${clusterId}`;
    if (Number.isFinite(projectId)) {
      filterBy = `${filterBy},projectId==${projectId}`;
    }

    const res = await workloadServiceApi.workloadsApi.countWorkloads(false, [filterBy]);
    return res.data.count;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

// new metrics apis
async function getWorkloadMetrics(
  workloadId: string,
  start: string,
  end: string,
  metricType: Array<WorkloadMetricType>,
  numberOfSamples?: number,
) {
  try {
    const res: AxiosResponse<MetricsResponse> = await workloadServiceApi.workloadsApi.getWorkloadMetrics(
      workloadId,
      metricType,
      start,
      end,
      numberOfSamples,
    );
    return res.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

// old metrics apis
async function getWorkloadGpuUtilization(
  workloadId: string,
  start: number,
  end: number,
  step = 100,
): Promise<TWorkloadMetricValue[]> {
  const query = `avg(runai_gpu_utilization_per_workload{workload_id="${workloadId}"})`;
  return (await prometheusService.rangeQuery(query, start, end, step)) || [];
}

async function getWorkloadCpuUtilization(
  workloadId: string,
  start: number,
  end: number,
  step = 100,
): Promise<TWorkloadMetricValue[]> {
  const query = `sum(runai_job_cpu_usage{workload_id="${workloadId}"})`;
  return (await prometheusService.rangeQuery(query, start, end, step)) || [];
}

async function getWorkloadGpuMemoryUtilization(
  workloadId: string,
  start: number,
  end: number,
  step = 100,
): Promise<TWorkloadMetricValue[]> {
  const query = `sum(runai_gpu_memory_used_mebibytes_per_workload{workload_id="${workloadId}"} * 1024 * 1024)`;
  return (await prometheusService.rangeQuery(query, start, end, step)) || [];
}

async function getWorkloadCpuMemoryUtilization(
  workloadId: string,
  start: number,
  end: number,
  step = 100,
): Promise<TWorkloadMetricValue[]> {
  const query = `sum(runai_job_memory_used_bytes{workload_id="${workloadId}"})`;
  return (await prometheusService.rangeQuery(query, start, end, step)) || [];
}

async function getAllWorkloadUtilizationMetrics(
  workloadId: string,
  rangeParams: IWorkloadMetricRangeParams,
): Promise<TWorkloadMetricValue[][]> {
  const { start, end, step } = rangeParams;
  return await Promise.all([
    getWorkloadGpuUtilization(workloadId, start, end, step),
    getWorkloadCpuUtilization(workloadId, start, end, step),
    getWorkloadGpuMemoryUtilization(workloadId, start, end, step),
    getWorkloadCpuMemoryUtilization(workloadId, start, end, step),
  ]);
}

async function handleFailedWorkloadClusterCreation(workloadId: string, e: unknown): Promise<void> {
  try {
    let phaseMessage = "failed to create workload in cluster";
    if (e instanceof HttpErrorResponse) {
      phaseMessage = e.message;
    }
    await patchWorkloadById(workloadId, {
      phase: Phase.Failed,
      phaseMessage,
    });
  } catch (e) {
    console.error("Failed to patch workload phase", e);
  }
}

//telemetry
async function getWorkloadsTelemetry(
  telemetryType: WorkloadTelemetryType,
  clusterId?: string,
  nodepoolName?: string,
  departmentId?: string,
  groupBy?: Array<EWorkloadTelemetryGroupBy>,
): Promise<TelemetryResponse> {
  try {
    const response = await workloadServiceApi.workloadsApi.getWorkloadsTelemetry(
      telemetryType,
      clusterId,
      nodepoolName,
      departmentId,
      groupBy,
    );
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

//telemetry csv
async function getWorkloadsTelemetryCsv(
  telemetryType: WorkloadTelemetryType,
  clusterId?: string,
  nodepoolName?: string,
  departmentId?: string,
  groupBy?: Array<EWorkloadTelemetryGroupBy>,
): Promise<void> {
  try {
    const query: Record<string, string | number | Array<string>> = {
      telemetryType: telemetryType,
    };
    if (clusterId) {
      query["clusterId"] = clusterId;
    }
    if (nodepoolName) {
      query["nodepoolName"] = nodepoolName;
    }
    if (departmentId) {
      query["departmentId"] = departmentId;
    }
    if (groupBy && groupBy.length > 0) {
      query["groupBy"] = groupBy;
    }
    await fileService.downloadFile(WORKLOADS_TELEMETRY_ENDPOINT, FileType.csv, undefined, query, {});
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
