import type { ITableColumn } from "@/models/table.model";
import type { EWorkloadStatus } from "@/common/status.constant";
import type { AssetCreationRequest, Scope } from "@/swagger-models/assets-service-client";

export type ObjectType = Record<string, unknown>;
// eslint-disable-next-line
export type CallbackFunction = (data?: any) => void;

export type SelectUpdateFilterFunction = (func: { (): void }) => void;

export interface ISelectOption {
  label: string;
  value: string | boolean | number | null | undefined;
  icon?: string;
  disable?: boolean;
  toolTip?: string; // TODO: we should rename this to info (this shows the question mark with tooltip)
  disabledTooltip?: string; // when the line is disabled, you can add a tooltip without a question mark icon
}

export interface IStorageTableColumns {
  columns: Array<ITableColumn>;
}

export interface ILocalStatus {
  oldStatus: string | undefined;
  loadingStatus: EWorkloadStatus;
  creationTimestamp: number;
}

export interface IDrawerSettings {
  isOpen: boolean;
  widthPercentage: number;
}

export interface IWebSocketSubscription {
  onMessage: (messageEvent: MessageEvent) => void;
  onError: (errorEvent: Event) => void;
  onOpen: (openEvent: Event) => void;
  onClose: (closeEvent: CloseEvent) => void;
  unsubscribe: () => void;
}

export interface IUIAssetCreationMeta extends Omit<AssetCreationRequest, "scope"> {
  scope: Scope | null;
}

export interface IScopeModel {
  scope: Scope | null;
  projectId?: number | null;
  departmentId?: string | null;
  clusterId?: string | null;
  parentClusterUuid?: string | null;
}

export enum EMemoryUnitLable {
  MB = "MB",
  GB = "GB",
  TB = "TB",
  MiB = "MiB",
  GiB = "GiB",
  TiB = "TiB",
}

export enum EMemoryUnitValue {
  MB = "M",
  GB = "G",
  TB = "T",
  GiB = "Gi",
  MiB = "Mi",
  TiB = "Ti",
}

export const basicMemoryUnitOptions: ISelectOption[] = [
  { label: EMemoryUnitLable.MB, value: EMemoryUnitValue.MB },
  { label: EMemoryUnitLable.GB, value: EMemoryUnitValue.GB },
];

export const allMemoryUnitOptions: ISelectOption[] = [
  { label: EMemoryUnitLable.MB, value: EMemoryUnitValue.MB },
  { label: EMemoryUnitLable.MiB, value: EMemoryUnitValue.MiB },
  { label: EMemoryUnitLable.GB, value: EMemoryUnitValue.GB },
  { label: EMemoryUnitLable.GiB, value: EMemoryUnitValue.GiB },
  { label: EMemoryUnitLable.TB, value: EMemoryUnitValue.TB },
  { label: EMemoryUnitLable.TiB, value: EMemoryUnitValue.TiB },
];

export enum ESidebarState {
  Mini = "mini",
  Full = "full",
  Hidden = "hidden",
}
