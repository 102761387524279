<template>
  <runai-select-card :value="isSelected" no-padding @clicked="$emit('card-clicked')" aid="model-catalog-card">
    <div class="model-catalog-card workload-card-content">
      <section class="model-catalog-cover">
        <img v-if="thumbnail" class="image-cover" :src="thumbnail" />
        <runai-svg-icon v-else name="model-asset" size="87px" />
      </section>

      <section class="model-catalog-details">
        <div class="model-title text-subtitle1 text-weight-medium">{{ title }}</div>
        <q-tooltip v-if="shouldShowTitleInTooltip" max-width="350px">{{ fullTitle }}</q-tooltip>
      </section>

      <section class="actions-container row justify-end">
        <q-btn
          round
          flat
          size="10px"
          icon="fa-regular fa-arrow-up-right-from-square"
          :href="huggingFaceHref"
          target="_blank"
          class="no-underline"
          @click="onHuggingFaceBtnClicked"
        >
          <q-tooltip>View on Hugging Face</q-tooltip>
        </q-btn>
      </section>
    </div>
  </runai-select-card>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { RunaiSelectCard } from "@/components/common/runai-select-card";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import type { ModelAsset } from "@/swagger-models/assets-service-client";

const MAX_CHARACTERS_IN_TITLE = 47;

export default defineComponent({
  components: {
    RunaiSelectCard,
    RunaiSvgIcon,
  },
  emits: ["card-clicked"],
  props: {
    data: {
      type: Object as PropType<ModelAsset>,
      required: true,
    },
    isSelected: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  computed: {
    thumbnail(): string {
      return this.data.spec.thumbnail || "";
    },
    huggingFaceHref(): string {
      return `https://huggingface.co/${this.data.spec.huggingfaceId}`;
    },
    title(): string {
      if (!this.shouldShowTitleInTooltip) return this.fullTitle;

      return this.fullTitle.substring(0, MAX_CHARACTERS_IN_TITLE) + "...";
    },
    shouldShowTitleInTooltip(): boolean {
      return this.fullTitle.length > MAX_CHARACTERS_IN_TITLE;
    },
    fullTitle(): string {
      return this.data?.spec.title || "";
    },
  },
  methods: {
    onHuggingFaceBtnClicked(ev: Event): void {
      ev.stopPropagation();
    },
  },
});
</script>

<style lang="scss" scoped>
.model-catalog-card {
  $card-padding: 15px;

  display: flex;
  flex-direction: column;
  width: 240px;
  height: 200px;

  .model-catalog-cover {
    height: 113px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $body-background-color;
  }

  .image-cover {
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .model-catalog-details {
    padding: 10px $card-padding 0 $card-padding;
    flex: 1;

    .model-title {
      line-height: 1.5rem;
    }
  }
}
</style>
