// Stores
import { defineStore } from "pinia";

// Models
import type { WorkloadCreationRequest, Inference } from "@/swagger-models/assets-service-client";
import type { IUIWorkloadCreation } from "@/models/workload.model";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";

import { deploymentService } from "@/services/control-plane/deployment.service/deployment.service";

export const useDeploymentStore = defineStore("Deployment", {
  state: () => ({
    inference: workloadUtil.getEmptyUIWorkloadCreation() as IUIWorkloadCreation,
    selectedInference: null as Inference | null,
    originRouteName: "" as string,
  }),
  getters: {
    deploymentName(): string {
      return this.inference.name || "";
    },
  },
  actions: {
    async createInference(inference: WorkloadCreationRequest): Promise<Inference> {
      return await deploymentService.create(inference);
    },
    setInference(inference: IUIWorkloadCreation): void {
      this.inference = inference;
    },
    resetCreateInferenceData(): void {
      this.inference = workloadUtil.getEmptyUIWorkloadCreation();
    },
    setOriginRoutePage(name: string): void {
      this.originRouteName = name;
    },
  },
});
