// any change here require change in IframePage component in the old app
export const POST_MESSAGE_EVENTS = {
  ACCESS_TOKEN: "access-token",
  TOKEN_UPDATED: "token-updated",
  REFRESH_TOKEN: "refresh-token",
  USER_DATA: "user-data",
  IFRAME_LOADED: "iframe-loaded",
  MODAL_MODE: "modal-mode",
  SIDENAV_STATE: "sidenav-state",
  NAVIGATE_TO: "navigate-to",
  UPDATE_LOCATION: "update-location",
  UPDATE_EXPRIED_TIMEOUT: "update-expried-timeout",

  INIT_APP: "init-app",
  CURRENT_CLUSTER: "current-cluster",
  CURRENT_CLUSTER_CHANGED: "current-cluster-changed",
  DO_LOGOUT: "do-logout",
  INVOKE_CLUSTER_ALERT_ACTION: "invoke-cluster-alert-action",
  GET_CLUSTER_HIDDEN_ALERTS: "get-cluster-hidden-alerts",
  UPDATE_SEARCH_PARAMS: "update-search-params",
  ON_ROUTE_LEAVE: "on-route-leave",
  ROUTE_BACK: "route-back",
  CURRENT_URL_CHANGED: "current-url-changed",
  CURRENT_URL_BACK: "current-url-back",
  REFRESH_CLUSTERS_LIST: "refresh-clusters-list",
  NEW_CLUSTER_CREATED: "new-cluster-created",
  LAST_PAGE_TO_BACK: "last-page-to-back",
  PERMISSIONS: "permissions",
  UPDATE_SETTINGS: "update-settings",
  SET_LOCAL_FLAG: "set-local-flag",
  PARENT_ORIGIN: "parent-origin",
  TOGGLE_WORKLOADS: "toggle-workloads",
  CONTACT_US: "contact-us",
};

export const NAVIGATION_EVENTS = {
  LEAVE_PAGE_REQUEST: "leave-page-request",
  LEAVE_PAGE_STATUS: "leave-page-status",
};
