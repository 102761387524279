// Stores
import { defineStore } from "pinia";
import { useClusterStore } from "./cluster.store";
// Services
import { accessRuleService } from "@/services/control-plane/rbac/access-rule.service/access-rule.service";
import { nodePoolService } from "@/services/control-plane/node-pool.service/node-pool.service";
import { projectService } from "@/services/control-plane/project.service/project.service";
import { researcherService } from "@/services/cluster/researcher.service/researcher.service";

// Models
import type { IFilterBy } from "@/models/filter.model";
import type {
  ILoadProjectsOptions,
  IProject,
  IProjectCreate,
  IProjectResources,
  ISelectedNodeAffinity,
} from "@/models/project.model";
import { NEW_PROJECT_DEFAULT_FIELDS } from "@/models/project.model";
import type { IClusterProject } from "@/models/cluster.model";
import { EClusterVersions } from "@/models/cluster.model";
import type { WorkspacePolicy, PolicyType } from "@/swagger-models/policy-service-client";
//utils
import { resourceUtil } from "@/utils/resource.util";
import { enrichScopeEntityWithAccessRules } from "@/utils/rbac.util/access-rule.util/access-rule.util";
import { usePermissionStore } from "@/stores/permissions.store";
import { Action, ResourceType, ScopeType, SubjectType } from "@/swagger-models/authorization-client";
import { useAuthStore } from "./auth.store";
import { allProjectColumns } from "@/table-models/project.table-model";
import { filterService } from "@/services/filter.service/filter.service";

export const useProjectStore = defineStore("Project", {
  state: () => ({
    projects: [] as Array<IProject>,
    projectsResources: [] as Array<IProjectResources>,
    loading: false as boolean,
    namespaces: {} as Record<string, string | undefined>,
    clusterStore: useClusterStore(),
    lastCreatedProjectId: null as null | number,
  }),
  getters: {
    hasProjects(): boolean {
      return this.projects.length > 0;
    },
    projectList(): Array<IProject> {
      return this.projects.filter((project: IProject) => project.id !== this.lastCreatedProjectId);
    },
    projectResourceList(): Array<IProjectResources> {
      const projectsCreatedAtMap = this.projects.reduce((acc: Record<string, string>, project: IProject) => {
        acc[project.id] = project.createdAt;
        return acc;
      }, {});
      return this.projectsResources.map((project: IProjectResources) => {
        const projectCreatedAt: string = projectsCreatedAtMap[project.id];
        if (!projectCreatedAt) return project;
        return { ...project, createdAt: projectCreatedAt };
      });
    },
    projectsNames(): string[] {
      return this.projects.map((project: IProject) => project.name);
    },
    lastCreatedProject(): IProject | null {
      if (!this.lastCreatedProjectId) return null;

      return this.projects.find((project: IProject) => project.id === this.lastCreatedProjectId) || null;
    },
    projectById:
      (state) =>
      (projectId: number, departmentId?: number): IProject | undefined => {
        const project = state.projects.find((project: IProject) => {
          if (departmentId) {
            return project.id === projectId && project.departmentId === departmentId;
          } else {
            return project.id === projectId;
          }
        });
        if (!project) return;
        const nodePoolsResources = resourceUtil.sortNodePools(project.nodePoolsResources);
        return { ...project, nodePoolsResources };
      },
  },
  actions: {
    async loadPolicy(workloadType: PolicyType, projectId: number): Promise<WorkspacePolicy> {
      return projectService.loadPolicy(workloadType, projectId);
    },
    async loadProjects(options?: ILoadProjectsOptions, filterBy: IFilterBy = {}): Promise<void> {
      const { withMetrics = false, withAccessRules = false, withNamespace = true } = options || {};

      try {
        this.loading = true;
        let projects = await projectService.list(this.clusterStore.currentCluster.uuid, filterBy);
        projects = filterService.filterListByTableFilters(projects, filterBy, allProjectColumns);

        if (withMetrics) {
          projects = await projectService.enrichProjectsWithResourcesMetrics(
            projects,
            this.clusterStore.currentClusterId,
            this.clusterStore.currentClusterVersion,
          );
        }
        if (withAccessRules && usePermissionStore().hasPermission(ResourceType.AccessRules, Action.Read)) {
          const scopeAccessRules = await accessRuleService.getAccessRules({
            scopeType: ScopeType.Project,
          });
          projects = enrichScopeEntityWithAccessRules(projects, scopeAccessRules);
        }

        this.projects = projects;

        if (withNamespace) {
          await this.loadNamespaces();
        }
      } finally {
        this.loading = false;
      }
    },
    async loadProjectsQuotas(): Promise<void> {
      this.projectsResources = await projectService.listQuota(this.clusterStore.currentCluster.uuid);
    },
    async loadNamespaces(): Promise<Record<string, string | undefined> | undefined> {
      const clusterVersion: string = this.clusterStore.currentCluster.version;
      if (!clusterVersion) return;
      // Workspaces are enabled only for 2.9 and above.
      // From 2.10 and above the namespace exists in the projects from the backend
      const isWorkspaceEnabled: boolean =
        clusterVersion.startsWith(EClusterVersions.v2_9) || clusterVersion.startsWith(`v${EClusterVersions.v2_9}`);

      if (isWorkspaceEnabled) {
        const projects: Array<IClusterProject> = await researcherService.getProjects();
        this.namespaces = projects.reduce((acc: Record<string, string | undefined>, project: IClusterProject) => {
          acc[project.name] = project.managedNamespace;
          return acc;
        }, {} as Record<string, string | undefined>);
      } else {
        this.namespaces = this.projects.reduce((acc: Record<string, string | undefined>, project: IProject) => {
          acc[project.name] = project.status?.namespace;
          return acc;
        }, {} as Record<string, string | undefined>);
      }
      return this.namespaces;
    },
    getNamespaceByProjectId(projectId: number | null): string {
      if (!projectId) return "";
      const projectName: string | undefined = this.projects.find((project: IProject) => project.id === projectId)?.name;
      if (!projectName) return "";
      return this.namespaces[projectName] || "";
    },
    getInteractiveNodeAffinityByProjectId(projectId: number): ISelectedNodeAffinity[] {
      if (!projectId) return [];
      return (
        this.projects.find((project: IProject) => project.id === projectId)?.nodeAffinity.interactive.selectedTypes || []
      );
    },
    getTrainingNodeAffinityByProjectId(projectId: number): ISelectedNodeAffinity[] {
      if (!projectId) return [];
      return this.projects.find((project: IProject) => project.id === projectId)?.nodeAffinity.train.selectedTypes || [];
    },
    async getEmptyProjectModel(isCpuEnabled: boolean, options?: Partial<IProjectCreate>): Promise<IProjectCreate> {
      const nodePoolsResources = await nodePoolService.getEmptyNodePoolsModel(
        this.clusterStore.currentCluster.uuid,
        isCpuEnabled,
      );
      const defaults = {
        ...NEW_PROJECT_DEFAULT_FIELDS,
        nodePoolsResources: nodePoolsResources,
      };
      return {
        ...defaults,
        ...options,
      };
    },
    updateProjectSubjectType(projectId: number, subjectType: SubjectType): void {
      this.projects = this.projects.map((project) => {
        if (project.id !== projectId) return project;
        return { ...project, roles: [subjectType, ...(project.roles || [])] };
      });
    },
    deleteProjectSubjectType(projectId: number, subjectTypeIndex: number): void {
      const projectIndex: number = this.projects.findIndex((project: IProject) => project.id === projectId);
      this.projects[projectIndex].roles?.splice(subjectTypeIndex, 1);
    },
    setLastCreatedProjectId(projectId: number): void {
      this.lastCreatedProjectId = projectId;
    },
    removeLastCreatedProject(): void {
      this.lastCreatedProjectId = null;
    },
    async createProject(project: IProjectCreate): Promise<IProject> {
      const createdProject = await projectService.createProject(project, this.clusterStore.currentCluster.uuid);
      await useAuthStore().loadUserOrgUnits();
      return createdProject;
    },
    async updateProject(project: IProject): Promise<IProject> {
      return await projectService.updateProject(project, this.clusterStore.currentCluster.uuid);
    },
    async deleteProject(project: IProject): Promise<IProject> {
      try {
        const deletedProject: IProject = await projectService.deleteProject(
          project.id,
          this.clusterStore.currentCluster.uuid,
        );

        const deletedProjectIndex = this.projects.findIndex((project: IProject) => project.id === deletedProject.id);
        this.projects.splice(deletedProjectIndex, 1);
        await useAuthStore().loadUserOrgUnits();
        return deletedProject;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
});
