import ModelSpecIndex from "@/views/model-spec/model-spec-index.vue";
import { MODEL_SPEC_NAMES } from "./model-spec.routes.names";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes";
import { ResourceType, Action } from "@/swagger-models/authorization-client";

import type { RunaiRouteRecord, RouteLocationNormalized, NavigationGuardNext } from "vue-router";

import { useSettingStore } from "@/stores/setting.store";
import { SettingKeys } from "@/models/setting.model";

export const modelSpecRoutes: RunaiRouteRecord = {
  path: "/models",
  component: () => import("@/views/model-spec/model-spec-app.vue"),
  children: [
    {
      path: "",
      name: MODEL_SPEC_NAMES.MODEL_SPEC_INDEX,
      component: ModelSpecIndex,
      meta: {
        pageTitle: "Models",
        fullWidth: false,
        fullPage: false,
        requiresAuth: true,
        resourceType: ResourceType.Deployments,
        minPermittedActions: [Action.Read],
      },
      beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
        const settingStore = useSettingStore();
        if (settingStore.isFeatureEnabled(SettingKeys.EnableModelCatalog)) {
          next();
        } else {
          next({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX });
        }
      },
    },
  ],
};
