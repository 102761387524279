<template>
  <section class="workload-create-form column items-center">
    <runai-form-wrapper
      :form-state="workloadModel"
      ref="workloadForm"
      :is-page-ready="true"
      @leave-page="$emit('leave-page', $event)"
    >
      <toggle-editing-form
        v-if="workloadConfig.toggleEditingForm"
        aid="toggle-editing-form"
        :is-enabled="workloadModel.enableEditingMaster"
        :section-options="workloadConfig.toggleEditingForm.sectionOptions"
        @toggle-editing-changed="onToggleEditingChanged"
      />

      <asset-project-section
        v-if="workloadConfig.project"
        :projects="workloadConfig.project.projects"
        :selected-project-id="workloadModel.projectId"
        :is-selectable="false"
        aid="project-section"
      >
        <template #custom-button>
          <q-btn icon="fa-solid fa-pencil" flat round size="sm" @click="onBack" aid="back-btn">
            <q-tooltip anchor="center right" self="center left">Change project</q-tooltip>
          </q-btn>
        </template>
      </asset-project-section>

      <title-and-back-section
        v-if="workloadConfig.modelSummary"
        label="Inference type"
        :summary="workloadConfig.modelSummary.summary"
        back-tooltip="Change model"
        @on-back="onBack"
      />

      <multi-node-section
        v-if="showMultiNodeSection"
        :is-selectable="false"
        :multi-node-section-model="workloadModel.distributed"
      >
        <template #custom-button>
          <q-btn icon="fa-solid fa-pencil" flat round size="sm" @click="onBack" aid="back-btn">
            <q-tooltip anchor="center right" self="center left">Edit</q-tooltip>
          </q-btn>
        </template>
      </multi-node-section>

      <workload-name-section
        v-if="workloadConfig.workloadName"
        :entity-type="workloadType"
        :default-open="workloadConfig.workloadName.sectionDefaultOpen"
        :name="workloadModel.name"
        @update:name="onNameChanged"
        :project-id="workloadModel.projectId"
        :cluster-id="clusterStore.currentClusterId"
        aid="workload-name-section"
      />

      <environment-section
        v-if="workloadConfig.environment"
        :key="`environment-section-${workloadModel.projectId}`"
        :entity-type="workloadType"
        aid="environment-section"
        :loading="false"
        :environments="workloadConfig.environment.environments"
        :environment-id="workloadModel.assets.environment || ''"
        :specific-env="environmentRunParams"
        @environment-changed="updateSelectedEnvironment"
        @create-new="onCreateNewEnvironment"
        :section-options="workloadConfig.environment.sectionOptions"
        :section-disabled="workloadConfig.environment.viewOnly"
      />

      <compute-resource-section
        v-if="workloadConfig.compute"
        :key="`compute-resource-section-${workloadModel.projectId}`"
        aid="compute-resource-section"
        :entity-type="workloadType"
        :loading="false"
        :compute-resources="workloadConfig.compute.computeResources"
        :node-affinity="workloadConfig.compute.nodeAffinity"
        @compute-resource-data-changed="onComputeResourceDataChanged"
        :compute-resource-data="computeResourceData"
        @create-new="onCreateNewComputeResource"
        :is-required="workloadConfig.compute.isRequired"
        :section-options="workloadConfig.compute.sectionOptions"
        :section-disabled="workloadConfig.compute.viewOnly"
      />

      <volume-section
        v-if="workloadConfig.volumes"
        aid="volume-section"
        :volumes="workloadModel.assets.uiVolumes || []"
        :storage-classes="workloadConfig.volumes.storageClasses"
        :section-disabled="workloadConfig.volumes.viewOnly"
        @update-volumes="onVolumesChanged"
      />

      <data-source-section
        v-if="workloadConfig.dataSource"
        :key="`data-source-section-${workloadModel.projectId}`"
        :entity-type="workloadType"
        aid="data-source-section"
        :loading="false"
        :data-sources="workloadConfig.dataSource.dataSources"
        :default-opened="workloadConfig.dataSource.sectionDefaultOpen"
        :selected-data-sources="workloadModel.assets.datasources || []"
        @datasource-changed="updateSelectedDataSources"
        @create-new="onCreateNewDataSource"
        :section-options="workloadConfig.dataSource.sectionOptions"
        :section-disabled="workloadConfig.dataSource.viewOnly"
      />

      <general-section
        v-if="workloadConfig.general"
        aid="general-section"
        :workload-form-type="workloadType"
        :general-model="generalSectionModel"
        @general-model-changed="updateGeneralModel"
        :section-disabled="workloadConfig.general.viewOnly"
      />
    </runai-form-wrapper>

    <section class="workload-create-form-actions-container row q-mt-md">
      <div class="error-messages-container row flex-1">
        <slot name="error-message" />
      </div>

      <div class="navigation-btns">
        <slot name="nav-btns" />
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

// components
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { AssetProjectSection } from "@/components/asset-project-section";
import { WorkloadNameSection } from "@/components/section/workload-name-section";
import { EnvironmentSection } from "@/components/section/environment-section";
import { ComputeResourceSection } from "@/components/section/compute-resource-section";
import { DataSourceSection } from "@/components/section/data-source-section";
import { VolumeSection } from "@/components/section/volume-section";
import { GeneralSection } from "@/components/section/general-section";
import { MultiNodeSection } from "@/components/section/multi-node-section";
import { ToggleEditingForm } from "@/components/section/toggle-editing-form";
import { TitleAndBackSection } from "@/components/section/title-and-back-section";

// models
import type { IWorkloadCreateFormConfig } from "./workload-create-form.model";
import { EWorkloadFormType, type IUIWorkloadCreation } from "@/models/workload.model";
import { type EnvironmentAsset, type AssetIdAndKind, Scope } from "@/swagger-models/assets-service-client";
import type { IItemizedListItem } from "@/components/common/runai-itemized-list";
import type { IUIGeneralSectionModel } from "@/components/section/general-section";
import type { IUIWorkloadEnvSectionModel } from "@/components/section/environment-section";
import { ENodePoolsListOrigin } from "@/components/section/compute-resource-section";
import type { IComputeSectionNodePoolData } from "@/components/section/compute-resource-section";
import type { IProject, INodePoolResources } from "@/models/project.model";
import { DATA_SOURCES_TYPES_TO_NAMES, type IUIVolume } from "@/models/data-source.model";
import type { IComputeSectionData } from "@/components/section/compute-resource-section";

// routes
import { TRAINING_ROUTE_NAMES } from "@/router/training.routes/training.routes.names";
import { WORKSPACE_ROUTE_NAMES } from "@/router/workspace.routes/workspace.routes.names";
import { INFERENCE_ROUTE_NAMES } from "@/router/deployment.routes/deployment.routes.names";

interface ISupportedRoutes {
  environment: {
    name: string;
    query: { fromPage: string };
  };
  compute: {
    name: string;
    query: { fromPage: string };
  };
  datasource: {
    pages: Record<string, string>;
  };
  onLeave: {
    name: string;
  };
}

function getSupportedRoutes(workloadType: EWorkloadFormType): ISupportedRoutes | undefined {
  if (workloadType === EWorkloadFormType.Training) {
    return {
      environment: {
        name: TRAINING_ROUTE_NAMES.TRAINING_ENVIRONMENT_NEW,
        query: { fromPage: TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT },
      },
      compute: {
        name: TRAINING_ROUTE_NAMES.TRAINING_COMPUTE_RESOURCE_NEW,
        query: { fromPage: TRAINING_ROUTE_NAMES.TRAINING_ASSETS_EDIT },
      },
      datasource: {
        pages: {
          [DATA_SOURCES_TYPES_TO_NAMES.NFS]: TRAINING_ROUTE_NAMES.TRAINING_NFS_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.PVC]: TRAINING_ROUTE_NAMES.TRAINING_PVC_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.S3]: TRAINING_ROUTE_NAMES.TRAINING_S3_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.GIT]: TRAINING_ROUTE_NAMES.TRAINING_GIT_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.HOST_PATH]: TRAINING_ROUTE_NAMES.TRAINING_HOST_PATH_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.CONFIG_MAP]: TRAINING_ROUTE_NAMES.TRAINING_CONFIG_MAP_NEW,
        },
      },
      onLeave: {
        name: TRAINING_ROUTE_NAMES.TRAINING_NEW,
      },
    };
  } else if (workloadType === EWorkloadFormType.Workspace) {
    return {
      environment: {
        name: WORKSPACE_ROUTE_NAMES.WORKSPACE_ENVIRONMENT_NEW,
        query: { fromPage: WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT },
      },
      compute: {
        name: WORKSPACE_ROUTE_NAMES.WORKSPACE_COMPUTE_RESOURCE_NEW,
        query: { fromPage: WORKSPACE_ROUTE_NAMES.WORKSPACE_ASSETS_EDIT },
      },
      datasource: {
        pages: {
          [DATA_SOURCES_TYPES_TO_NAMES.NFS]: WORKSPACE_ROUTE_NAMES.WORKSPACE_NFS_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.PVC]: WORKSPACE_ROUTE_NAMES.WORKSPACE_PVC_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.S3]: WORKSPACE_ROUTE_NAMES.WORKSPACE_S3_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.GIT]: WORKSPACE_ROUTE_NAMES.WORKSPACE_GIT_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.HOST_PATH]: WORKSPACE_ROUTE_NAMES.WORKSPACE_HOST_PATH_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.CONFIG_MAP]: WORKSPACE_ROUTE_NAMES.WORKSPACE_CONFIG_MAP_NEW,
        },
      },
      onLeave: {
        name: WORKSPACE_ROUTE_NAMES.WORKSPACE_NEW,
      },
    };
  } else if (workloadType === EWorkloadFormType.Inference) {
    return {
      environment: {
        name: INFERENCE_ROUTE_NAMES.INFERENCE_ENVIRONMENT_NEW,
        query: { fromPage: INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT },
      },
      compute: {
        name: INFERENCE_ROUTE_NAMES.INFERENCE_COMPUTE_RESOURCE_NEW,
        query: { fromPage: INFERENCE_ROUTE_NAMES.INFERENCE_ASSETS_EDIT },
      },
      datasource: {
        pages: {
          [DATA_SOURCES_TYPES_TO_NAMES.NFS]: INFERENCE_ROUTE_NAMES.INFERENCE_NFS_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.PVC]: INFERENCE_ROUTE_NAMES.INFERENCE_PVC_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.S3]: INFERENCE_ROUTE_NAMES.INFERENCE_S3_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.GIT]: INFERENCE_ROUTE_NAMES.INFERENCE_GIT_NEW,
          [DATA_SOURCES_TYPES_TO_NAMES.HOST_PATH]: INFERENCE_ROUTE_NAMES.INFERENCE_HOST_PATH_NEW,
        },
      },
      onLeave: {
        name: INFERENCE_ROUTE_NAMES.INFERENCE_NEW,
      },
    };
  }
}

// utils
import { deepCopy, pick } from "@/utils/common.util";
import { useClusterStore } from "@/stores/cluster.store";

export default defineComponent({
  components: {
    RunaiFormWrapper,
    AssetProjectSection,
    WorkloadNameSection,
    MultiNodeSection,
    EnvironmentSection,
    ComputeResourceSection,
    DataSourceSection,
    VolumeSection,
    GeneralSection,
    ToggleEditingForm,
    TitleAndBackSection,
  },
  emits: ["workload-changed", "volume-changed", "leave-page", "back-clicked"],
  props: {
    workloadType: {
      type: String as PropType<EWorkloadFormType>,
      required: true,
    },
    workload: {
      type: Object as PropType<IUIWorkloadCreation>,
      required: true,
    },
    workloadConfig: {
      type: Object as PropType<IWorkloadCreateFormConfig>,
      required: true,
    },
    selectedProject: {
      type: Object as PropType<IProject>,
      required: true,
    },
    workloadFormName: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      workloadForm: null as HTMLFormElement | null,
      workloadModel: {
        name: "",
        projectId: -1,
        assets: {
          environment: "",
          compute: "",
        },
        specificEnv: {},
      } as IUIWorkloadCreation,
      name: "",
      clusterStore: useClusterStore(),
    };
  },
  created() {
    this.workloadModel = deepCopy(this.workload);
  },
  mounted() {
    this.workloadForm = this.$refs.workloadForm as HTMLFormElement;
  },
  computed: {
    workers(): number | null {
      return this.workloadModel.distributed?.numWorkers || null;
    },
    selectedEnvironment(): EnvironmentAsset | undefined {
      if (!this.workloadConfig.environment) return;
      return this.workloadConfig.environment.environments.find(
        (env: EnvironmentAsset) => env.meta.id === this.workloadModel.assets.environment,
      );
    },
    environmentRunParams(): IUIWorkloadEnvSectionModel {
      const specificEnv = this.workloadModel.specificEnv;
      return {
        command: specificEnv?.command || "",
        args: specificEnv?.args || "",
        environmentVariables: specificEnv?.environmentVariables || [],
        connections: specificEnv?.connections || [],
        runAsGid: specificEnv?.runAsGid || null,
        runAsUid: specificEnv?.runAsUid || null,
        supplementalGroups: specificEnv?.supplementalGroups || null,
      };
    },
    computeResourceData(): IComputeSectionData {
      return {
        computeResourceId: this.workloadModel.assets.compute || null,
        nodeType: this.workloadModel.specificEnv?.nodeType || null,
        nodePools: this.nodePoolsData,
        workers: this.workers || null,
        autoScaleData: this.workloadModel.specificEnv?.autoScaleData || undefined,
      };
    },
    generalSectionModel(): IUIGeneralSectionModel {
      return {
        allowOverQuota: this.workloadModel.specificEnv?.allowOverQuota || false,
        autoDeletionTimeAfterCompletionSeconds: Number.isInteger(
          this.workloadModel.specificEnv?.autoDeletionTimeAfterCompletionSeconds,
        )
          ? this.workloadModel.specificEnv?.autoDeletionTimeAfterCompletionSeconds
          : null,
        annotations: this.workloadModel.specificEnv?.annotations,
        labels: this.workloadModel.specificEnv?.labels,
      };
    },
    allNodePoolsOptions(): Array<string> {
      return (
        this.selectedProject?.nodePoolsResources.map(
          (nodePoolResource: INodePoolResources) => nodePoolResource.nodePool.name,
        ) || []
      );
    },
    nodePoolsData(): IComputeSectionNodePoolData | null {
      if (!this.workloadModel.specificEnv || !this.workloadModel.specificEnv.nodePools) {
        return null;
      }

      return {
        defaultNodePools: this.workloadModel.specificEnv.nodePools,
        allNodePoolsOptions: this.allNodePoolsOptions,
        nodePoolsListOrigin: ENodePoolsListOrigin.Project,
      };
    },
    showMultiNodeSection(): boolean {
      return this.clusterStore.isVersionSupportDistributedTraining && this.workloadType === EWorkloadFormType.Training;
    },
  },
  methods: {
    updateSelectedEnvironment(envData: { environmentId: string; specificEnv: IUIWorkloadEnvSectionModel }): void {
      this.workloadModel.assets.environment = envData.environmentId;
      const environmentVariables = envData.specificEnv.environmentVariables.map((item: IItemizedListItem) =>
        pick(item, "name", "value", "deleted"),
      );
      this.workloadModel.specificEnv = {
        ...this.workloadModel.specificEnv,
        ...envData.specificEnv,
        environmentVariables,
      };
      this.onModelChanged();
    },
    updateGeneralModel(generalModel: IUIGeneralSectionModel): void {
      this.workloadModel.specificEnv = {
        ...this.workloadModel.specificEnv,
        ...generalModel,
      };
      this.onModelChanged();
    },
    onComputeResourceDataChanged(computeResourceData: IComputeSectionData): void {
      this.workloadModel.assets.compute = computeResourceData.computeResourceId;
      if (this.workloadModel.specificEnv) {
        this.workloadModel.specificEnv.nodePools = computeResourceData.nodePools?.defaultNodePools;
        this.workloadModel.specificEnv.nodeType = computeResourceData.nodeType || null;
        this.workloadModel.specificEnv.autoScaleData = computeResourceData.autoScaleData;
      }

      if (this.workloadModel.distributed) {
        this.workloadModel.distributed.numWorkers = computeResourceData.workers || 1;
      }

      this.onModelChanged();
    },
    onVolumesChanged(uiVolumes: Array<IUIVolume>): void {
      this.workloadModel.assets = {
        ...this.workloadModel.assets,
        uiVolumes,
      };
      this.onModelChanged();
    },
    updateSelectedDataSources(dataSources: Array<AssetIdAndKind>): void {
      this.workloadModel.assets.datasources = dataSources;
      this.onModelChanged();
    },
    onCreateNewEnvironment(): void {
      this.onModelChanged();
      const routes: ISupportedRoutes | undefined = getSupportedRoutes(this.workloadType);
      if (!routes) return;
      this.$router.push({
        name: routes.environment.name,
        query: {
          ...routes.environment.query,
          projectId: this.workload.projectId,
          scope: Scope.Project,
          distFramework: this.workload.distributed?.distFramework,
          workloadType: this.workloadType,
          workloadFormName: this.workloadFormName,
        },
      });
    },
    onCreateNewComputeResource(): void {
      this.onModelChanged();
      const routes: ISupportedRoutes | undefined = getSupportedRoutes(this.workloadType);
      if (!routes) return;

      this.$router.push({
        name: routes.compute.name,
        query: {
          ...routes.compute.query,
          projectId: this.workload.projectId,
          scope: Scope.Project,
          workloadFormName: this.workloadFormName,
        },
      });
    },
    onCreateNewDataSource(selectedDataSourceType: string): void {
      this.onModelChanged();
      const routes: ISupportedRoutes | undefined = getSupportedRoutes(this.workloadType);
      if (!routes) return;

      this.$router.push({
        name: routes.datasource.pages[selectedDataSourceType],
        query: { projectId: this.workload.projectId, workloadFormName: this.workloadFormName, scope: Scope.Project },
      });
    },
    onNameChanged(name: string) {
      this.workloadModel.name = name;
      this.onModelChanged();
    },
    onToggleEditingChanged(isEnabled: boolean): void {
      this.workloadModel.enableEditingMaster = isEnabled;
      this.onModelChanged();
    },
    onBack(): void {
      this.$emit("back-clicked");
    },
    onModelChanged(): void {
      this.$emit("workload-changed", this.workloadModel);
    },
    beforeLeave(): void {
      (this.$refs.workloadForm as HTMLFormElement).beforeLeave();
    },
    validate(): Promise<boolean> {
      return (this.$refs.workloadForm as HTMLFormElement).validate();
    },
  },
});
</script>

<style lang="scss" scoped>
.workload-create-form {
  .workload-create-form-actions-container {
    width: 100%;

    display: flex;
    justify-content: space-between;
  }
}
</style>
