/* tslint:disable */
/* eslint-disable */
/**
 * ClusterService
 * An API for managing RunAi cluster objects (nodes).
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * The ancestors of an org unit.
 * @export
 * @interface Ancestors
 */
export interface Ancestors {
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'tenant_id': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof Ancestors
     */
    'cluster_uuid'?: string | null;
    /**
     * The id of the department.
     * @type {string}
     * @memberof Ancestors
     */
    'department_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'project_id'?: string | null;
}
/**
 * 
 * @export
 * @interface ClusterCreationRequest
 */
export interface ClusterCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterCreationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterCreationRequest
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusterCreationRequest
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface ClusterDependenciesStatus
 */
export interface ClusterDependenciesStatus {
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterDependenciesStatus
     */
    'required': { [key: string]: ClusterDependencyStatus; };
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterDependenciesStatus
     */
    'optional': { [key: string]: ClusterDependencyStatus; };
}
/**
 * 
 * @export
 * @interface ClusterDependencyStatus
 */
export interface ClusterDependencyStatus {
    /**
     * whether the dependency is available
     * @type {boolean}
     * @memberof ClusterDependencyStatus
     */
    'available': boolean;
    /**
     * the reason for the dependency status
     * @type {string}
     * @memberof ClusterDependencyStatus
     */
    'reason'?: string;
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterDependencyStatus
     */
    'components'?: { [key: string]: ClusterDependencyStatus; };
}
/**
 * The cluster\'s displayed status.
 * @export
 * @interface ClusterDisplayedStatus
 */
export interface ClusterDisplayedStatus {
    /**
     * 
     * @type {Array<ClusterReportedStatusConditionsInner>}
     * @memberof ClusterDisplayedStatus
     */
    'conditions'?: Array<ClusterReportedStatusConditionsInner>;
    /**
     * OperandStatuses specifies the status of the managed operands
     * @type {{ [key: string]: ClusterReportedStatusOperandsValue; }}
     * @memberof ClusterDisplayedStatus
     */
    'operands'?: { [key: string]: ClusterReportedStatusOperandsValue; };
    /**
     * 
     * @type {ClusterReportedStatusPlatform}
     * @memberof ClusterDisplayedStatus
     */
    'platform'?: ClusterReportedStatusPlatform | null;
    /**
     * 
     * @type {ClusterDependenciesStatus}
     * @memberof ClusterDisplayedStatus
     */
    'dependencies'?: ClusterDependenciesStatus;
    /**
     * The cluster\'s state.
     * @type {string}
     * @memberof ClusterDisplayedStatus
     */
    'state'?: ClusterDisplayedStatusStateEnum;
}

export const ClusterDisplayedStatusStateEnum = {
    WaitingToConnect: 'WaitingToConnect',
    Connected: 'Connected',
    Disconnected: 'Disconnected',
    MissingPrerequisites: 'MissingPrerequisites',
    ServiceIssues: 'ServiceIssues',
    Unknown: 'Unknown'
} as const;

export type ClusterDisplayedStatusStateEnum = typeof ClusterDisplayedStatusStateEnum[keyof typeof ClusterDisplayedStatusStateEnum];

/**
 * 
 * @export
 * @interface ClusterDisplayedStatusAllOf
 */
export interface ClusterDisplayedStatusAllOf {
    /**
     * The cluster\'s state.
     * @type {string}
     * @memberof ClusterDisplayedStatusAllOf
     */
    'state'?: ClusterDisplayedStatusAllOfStateEnum;
}

export const ClusterDisplayedStatusAllOfStateEnum = {
    WaitingToConnect: 'WaitingToConnect',
    Connected: 'Connected',
    Disconnected: 'Disconnected',
    MissingPrerequisites: 'MissingPrerequisites',
    ServiceIssues: 'ServiceIssues',
    Unknown: 'Unknown'
} as const;

export type ClusterDisplayedStatusAllOfStateEnum = typeof ClusterDisplayedStatusAllOfStateEnum[keyof typeof ClusterDisplayedStatusAllOfStateEnum];

/**
 * 
 * @export
 * @interface ClusterHistory
 */
export interface ClusterHistory {
    /**
     * the cluster id
     * @type {string}
     * @memberof ClusterHistory
     */
    'cluster_id': string;
    /**
     * the cluster version
     * @type {string}
     * @memberof ClusterHistory
     */
    'version': string;
    /**
     * the cluster version update timestamp in unix format
     * @type {number}
     * @memberof ClusterHistory
     */
    'update_timestamp': number;
}
/**
 * 
 * @export
 * @interface ClusterInfoSyncRequest
 */
export interface ClusterInfoSyncRequest {
    /**
     * the cluster domain
     * @type {string}
     * @memberof ClusterInfoSyncRequest
     */
    'domain': string;
    /**
     * the cluster version
     * @type {string}
     * @memberof ClusterInfoSyncRequest
     */
    'version': string;
    /**
     * 
     * @type {ClusterInfoSyncRequestDependencies}
     * @memberof ClusterInfoSyncRequest
     * @deprecated
     */
    'dependencies'?: ClusterInfoSyncRequestDependencies | null;
    /**
     * 
     * @type {ClusterInfoSyncRequestStatus}
     * @memberof ClusterInfoSyncRequest
     */
    'status'?: ClusterInfoSyncRequestStatus | null;
}
/**
 * Deprecated. Use status.dependencies instead.
 * @export
 * @interface ClusterInfoSyncRequestDependencies
 */
export interface ClusterInfoSyncRequestDependencies {
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterInfoSyncRequestDependencies
     */
    'required': { [key: string]: ClusterDependencyStatus; };
    /**
     * 
     * @type {{ [key: string]: ClusterDependencyStatus; }}
     * @memberof ClusterInfoSyncRequestDependencies
     */
    'optional': { [key: string]: ClusterDependencyStatus; };
}
/**
 * 
 * @export
 * @interface ClusterInfoSyncRequestStatus
 */
export interface ClusterInfoSyncRequestStatus {
    /**
     * 
     * @type {Array<ClusterReportedStatusConditionsInner>}
     * @memberof ClusterInfoSyncRequestStatus
     */
    'conditions'?: Array<ClusterReportedStatusConditionsInner>;
    /**
     * OperandStatuses specifies the status of the managed operands
     * @type {{ [key: string]: ClusterReportedStatusOperandsValue; }}
     * @memberof ClusterInfoSyncRequestStatus
     */
    'operands'?: { [key: string]: ClusterReportedStatusOperandsValue; };
    /**
     * 
     * @type {ClusterReportedStatusPlatform}
     * @memberof ClusterInfoSyncRequestStatus
     */
    'platform'?: ClusterReportedStatusPlatform | null;
    /**
     * 
     * @type {ClusterDependenciesStatus}
     * @memberof ClusterInfoSyncRequestStatus
     */
    'dependencies'?: ClusterDependenciesStatus;
}
/**
 * 
 * @export
 * @interface ClusterInstallationInfoResponse
 */
export interface ClusterInstallationInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof ClusterInstallationInfoResponse
     */
    'installationStr': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInstallationInfoResponse
     */
    'repositoryName': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInstallationInfoResponse
     */
    'chartRepoURL': string;
    /**
     * 
     * @type {string}
     * @memberof ClusterInstallationInfoResponse
     */
    'clientSecret': string;
}
/**
 * 
 * @export
 * @interface ClusterInstallationResponse
 */
export interface ClusterInstallationResponse {
    /**
     * 
     * @type {string}
     * @memberof ClusterInstallationResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface ClusterLivenessSyncRequest
 */
export interface ClusterLivenessSyncRequest {
    /**
     * 
     * @type {number}
     * @memberof ClusterLivenessSyncRequest
     */
    'timeTag': number;
}
/**
 * the cluster status reported by the cluster
 * @export
 * @interface ClusterReportedStatus
 */
export interface ClusterReportedStatus {
    /**
     * 
     * @type {Array<ClusterReportedStatusConditionsInner>}
     * @memberof ClusterReportedStatus
     */
    'conditions'?: Array<ClusterReportedStatusConditionsInner>;
    /**
     * OperandStatuses specifies the status of the managed operands
     * @type {{ [key: string]: ClusterReportedStatusOperandsValue; }}
     * @memberof ClusterReportedStatus
     */
    'operands'?: { [key: string]: ClusterReportedStatusOperandsValue; };
    /**
     * 
     * @type {ClusterReportedStatusPlatform}
     * @memberof ClusterReportedStatus
     */
    'platform'?: ClusterReportedStatusPlatform | null;
    /**
     * 
     * @type {ClusterDependenciesStatus}
     * @memberof ClusterReportedStatus
     */
    'dependencies'?: ClusterDependenciesStatus;
}
/**
 * Condition contains details for one aspect of the current state of this API Resource
 * @export
 * @interface ClusterReportedStatusConditionsInner
 */
export interface ClusterReportedStatusConditionsInner {
    /**
     * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'lastTransitionTime': string;
    /**
     * message is a human readable message indicating details about the transition. This may be an empty string.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'message': string;
    /**
     * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
     * @type {number}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'observedGeneration'?: number;
    /**
     * reason contains a programmatic identifier indicating the reason for the condition\'s last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'reason': string;
    /**
     * status of the condition, one of True, False, Unknown.
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'status': ClusterReportedStatusConditionsInnerStatusEnum;
    /**
     * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
     * @type {string}
     * @memberof ClusterReportedStatusConditionsInner
     */
    'type': string;
}

export const ClusterReportedStatusConditionsInnerStatusEnum = {
    True: 'True',
    False: 'False',
    Unknown: 'Unknown'
} as const;

export type ClusterReportedStatusConditionsInnerStatusEnum = typeof ClusterReportedStatusConditionsInnerStatusEnum[keyof typeof ClusterReportedStatusConditionsInnerStatusEnum];

/**
 * Status specifies the status of an operand. And operand manages resources, some of which are not immediately available and need their status monitored, such as Deployments, Daemonsets, Ingressess etc. Some resources are immediately available and should not be monitored once accepted by the cluster, such as Services, ConfigMaps, Secrets etc. If all resources of an operand are ready, a Status with Ready set to \'true\' and a nil \'Reasons\' slice should be returned, otherwise, a Status with \'Reasons\' slice containig the reason why the Operand is not ready (Deployment pods are not ready for example.)
 * @export
 * @interface ClusterReportedStatusOperandsValue
 */
export interface ClusterReportedStatusOperandsValue {
    /**
     * LastTransitionTime specifies the last time the operand readiness changed
     * @type {string}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'lastTransitionTime'?: string;
    /**
     * Ready specifies if the operand is ready or not
     * @type {boolean}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'ready': boolean;
    /**
     * Reasons specifies the reasons why the operand is not ready
     * @type {Array<string>}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'reasons'?: Array<string>;
    /**
     * UnreadyThresholdCrossed specifies if the operand has been unready for longer than the threshold
     * @type {boolean}
     * @memberof ClusterReportedStatusOperandsValue
     */
    'unreadyThresholdCrossed'?: boolean;
}
/**
 * 
 * @export
 * @interface ClusterReportedStatusPlatform
 */
export interface ClusterReportedStatusPlatform {
    /**
     * the cluster platform type
     * @type {string}
     * @memberof ClusterReportedStatusPlatform
     */
    'type'?: ClusterReportedStatusPlatformTypeEnum;
    /**
     * the cluster kubernetes version
     * @type {string}
     * @memberof ClusterReportedStatusPlatform
     */
    'kubeVersion'?: string | null;
}

export const ClusterReportedStatusPlatformTypeEnum = {
    Vanilla: 'vanilla',
    Openshift: 'openshift',
    Rke: 'rke',
    Gke: 'gke',
    Aks: 'aks',
    Eks: 'eks'
} as const;

export type ClusterReportedStatusPlatformTypeEnum = typeof ClusterReportedStatusPlatformTypeEnum[keyof typeof ClusterReportedStatusPlatformTypeEnum];

/**
 * 
 * @export
 * @interface ClusterUpdateRequest
 */
export interface ClusterUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterUpdateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ClusterUrlRequest
 */
export interface ClusterUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusterUrlRequest
     */
    'targetHost': string;
}
/**
 * 
 * @export
 * @interface ClusterUrlResponse
 */
export interface ClusterUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof ClusterUrlResponse
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface DisplayedCluster
 */
export interface DisplayedCluster {
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'uuid': string;
    /**
     * 
     * @type {number}
     * @memberof DisplayedCluster
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'domain'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'version'?: string | null;
    /**
     * 
     * @type {ClusterDisplayedStatus}
     * @memberof DisplayedCluster
     */
    'status'?: ClusterDisplayedStatus | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DisplayedCluster
     */
    'lastLiveness'?: string | null;
}
/**
 * 
 * @export
 * @interface GetClusterName200Response
 */
export interface GetClusterName200Response {
    /**
     * 
     * @type {string}
     * @memberof GetClusterName200Response
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GpuInfo
 */
export interface GpuInfo {
    /**
     * 
     * @type {string}
     * @memberof GpuInfo
     */
    'gpuType': string;
    /**
     * 
     * @type {number}
     * @memberof GpuInfo
     */
    'gpuCount': number;
}
/**
 * 
 * @export
 * @interface MeasurementResponse
 */
export interface MeasurementResponse {
    /**
     * specifies what data returned
     * @type {string}
     * @memberof MeasurementResponse
     */
    'type': string;
    /**
     * labels of the metric measurement
     * @type {{ [key: string]: string; }}
     * @memberof MeasurementResponse
     */
    'labels'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<MeasurementResponseValuesInner>}
     * @memberof MeasurementResponse
     */
    'values': Array<MeasurementResponseValuesInner> | null;
}
/**
 * 
 * @export
 * @interface MeasurementResponseValuesInner
 */
export interface MeasurementResponseValuesInner {
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseValuesInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseValuesInner
     */
    'timestamp': string | null;
}
/**
 * 
 * @export
 * @interface MetricsResponse
 */
export interface MetricsResponse {
    /**
     * 
     * @type {Array<MeasurementResponse>}
     * @memberof MetricsResponse
     */
    'measurements': Array<MeasurementResponse>;
}
/**
 * specifies what data to request
 * @export
 * @enum {string}
 */

export const MetricsType = {
    GpuUtilization: 'GPU_UTILIZATION',
    GpuMemoryUtilization: 'GPU_MEMORY_UTILIZATION',
    CpuUtilization: 'CPU_UTILIZATION',
    CpuMemoryUtilization: 'CPU_MEMORY_UTILIZATION',
    TotalGpu: 'TOTAL_GPU',
    GpuQuota: 'GPU_QUOTA',
    AllocatedGpu: 'ALLOCATED_GPU',
    AvgWorkloadWaitTime: 'AVG_WORKLOAD_WAIT_TIME'
} as const;

export type MetricsType = typeof MetricsType[keyof typeof MetricsType];


/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * The calculated status of the node.
     * @type {string}
     * @memberof Node
     */
    'status': NodeStatusEnum;
    /**
     * 
     * @type {Array<NodeStatusConditionDetails>}
     * @memberof Node
     */
    'conditions'?: Array<NodeStatusConditionDetails>;
    /**
     * 
     * @type {Array<NodeTaint>}
     * @memberof Node
     */
    'taints'?: Array<NodeTaint>;
    /**
     * The node\'s NodePool.
     * @type {string}
     * @memberof Node
     */
    'nodePool': string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'createdAt': string;
    /**
     * 
     * @type {GpuInfo}
     * @memberof Node
     */
    'gpuInfo'?: GpuInfo | null;
    /**
     * The name of the node
     * @type {string}
     * @memberof Node
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'clusterUuid': string;
    /**
     * 
     * @type {string}
     * @memberof Node
     */
    'updatedAt': string;
}

export const NodeStatusEnum = {
    Ready: 'Ready',
    NotReady: 'NotReady',
    Unknown: 'Unknown'
} as const;

export type NodeStatusEnum = typeof NodeStatusEnum[keyof typeof NodeStatusEnum];

/**
 * 
 * @export
 * @interface NodeAdditionalFields
 */
export interface NodeAdditionalFields {
    /**
     * The name of the node
     * @type {string}
     * @memberof NodeAdditionalFields
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface NodeAdditionalReadFields
 */
export interface NodeAdditionalReadFields {
    /**
     * 
     * @type {string}
     * @memberof NodeAdditionalReadFields
     */
    'clusterUuid': string;
    /**
     * 
     * @type {string}
     * @memberof NodeAdditionalReadFields
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface NodeForSync
 */
export interface NodeForSync {
    /**
     * The name of the node
     * @type {string}
     * @memberof NodeForSync
     */
    'name': string;
    /**
     * 
     * @type {NodeInfo}
     * @memberof NodeForSync
     */
    'nodeInfo': NodeInfo;
}
/**
 * 
 * @export
 * @interface NodeForSyncAllOf
 */
export interface NodeForSyncAllOf {
    /**
     * 
     * @type {NodeInfo}
     * @memberof NodeForSyncAllOf
     */
    'nodeInfo': NodeInfo;
}
/**
 * 
 * @export
 * @interface NodeInfo
 */
export interface NodeInfo {
    /**
     * The calculated status of the node.
     * @type {string}
     * @memberof NodeInfo
     */
    'status': NodeInfoStatusEnum;
    /**
     * 
     * @type {Array<NodeStatusConditionDetails>}
     * @memberof NodeInfo
     */
    'conditions'?: Array<NodeStatusConditionDetails>;
    /**
     * 
     * @type {Array<NodeTaint>}
     * @memberof NodeInfo
     */
    'taints'?: Array<NodeTaint>;
    /**
     * The node\'s NodePool.
     * @type {string}
     * @memberof NodeInfo
     */
    'nodePool': string;
    /**
     * 
     * @type {string}
     * @memberof NodeInfo
     */
    'createdAt': string;
    /**
     * 
     * @type {GpuInfo}
     * @memberof NodeInfo
     */
    'gpuInfo'?: GpuInfo | null;
}

export const NodeInfoStatusEnum = {
    Ready: 'Ready',
    NotReady: 'NotReady',
    Unknown: 'Unknown'
} as const;

export type NodeInfoStatusEnum = typeof NodeInfoStatusEnum[keyof typeof NodeInfoStatusEnum];

/**
 * 
 * @export
 * @interface NodeStatusConditionDetails
 */
export interface NodeStatusConditionDetails {
    /**
     * Type of node condition.
     * @type {string}
     * @memberof NodeStatusConditionDetails
     */
    'type': string;
    /**
     * (brief) reason for the condition\'s last transition.
     * @type {string}
     * @memberof NodeStatusConditionDetails
     */
    'reason': string;
    /**
     * Human readable message indicating details about last transition.
     * @type {string}
     * @memberof NodeStatusConditionDetails
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface NodeTaint
 */
export interface NodeTaint {
    /**
     * The taint key to be applied to a node.
     * @type {string}
     * @memberof NodeTaint
     */
    'key': string;
    /**
     * The taint value corresponding to the taint key.
     * @type {string}
     * @memberof NodeTaint
     */
    'value'?: string;
    /**
     * The effect of the taint on pods that do not tolerate the taint.
     * @type {string}
     * @memberof NodeTaint
     */
    'effect': NodeTaintEffectEnum;
}

export const NodeTaintEffectEnum = {
    NoSchedule: 'NoSchedule',
    PreferNoSchedule: 'PreferNoSchedule',
    NoExecute: 'NoExecute'
} as const;

export type NodeTaintEffectEnum = typeof NodeTaintEffectEnum[keyof typeof NodeTaintEffectEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const NodeTelemetryType = {
    ReadyGpuNodes: 'READY_GPU_NODES',
    ReadyGpus: 'READY_GPUS',
    TotalGpuNodes: 'TOTAL_GPU_NODES',
    TotalGpus: 'TOTAL_GPUS',
    FreeGpus: 'FREE_GPUS'
} as const;

export type NodeTelemetryType = typeof NodeTelemetryType[keyof typeof NodeTelemetryType];


/**
 * 
 * @export
 * @interface Nodes
 */
export interface Nodes {
    /**
     * 
     * @type {Array<Node>}
     * @memberof Nodes
     */
    'nodes': Array<Node>;
}
/**
 * 
 * @export
 * @interface NodesSyncRequest
 */
export interface NodesSyncRequest {
    /**
     * 
     * @type {Array<NodeForSync>}
     * @memberof NodesSyncRequest
     */
    'nodesForSync': Array<NodeForSync>;
    /**
     * The first node to sync. Will delete all nodes that don\'t exist in the request, after this node. If empty, all nodes before the first one sent will be deleted.
     * @type {string}
     * @memberof NodesSyncRequest
     */
    'from'?: string;
    /**
     * The last node to sync. Will delete all nodes that don\'t exist in the request, before this node. If empty, all nodes after the last one sent will be deleted.
     * @type {string}
     * @memberof NodesSyncRequest
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface TelemetryResponse
 */
export interface TelemetryResponse {
    /**
     * specifies what data returned
     * @type {string}
     * @memberof TelemetryResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponse
     */
    'timestamp': string;
    /**
     * 
     * @type {Array<TelemetryResponseValuesInner>}
     * @memberof TelemetryResponse
     */
    'values': Array<TelemetryResponseValuesInner>;
}
/**
 * 
 * @export
 * @interface TelemetryResponseValuesInner
 */
export interface TelemetryResponseValuesInner {
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponseValuesInner
     */
    'value': string;
    /**
     * columns the data is grouped by
     * @type {Array<TelemetryResponseValuesInnerGroupsInner>}
     * @memberof TelemetryResponseValuesInner
     */
    'groups'?: Array<TelemetryResponseValuesInnerGroupsInner>;
}
/**
 * 
 * @export
 * @interface TelemetryResponseValuesInnerGroupsInner
 */
export interface TelemetryResponseValuesInnerGroupsInner {
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponseValuesInnerGroupsInner
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponseValuesInnerGroupsInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponseValuesInnerGroupsInner
     */
    'name'?: string;
}

/**
 * ClustersApi - axios parameter creator
 * @export
 */
export const ClustersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create cluster
         * @param {ClusterCreationRequest} clusterCreationRequest The cluster to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCluster: async (clusterCreationRequest: ClusterCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterCreationRequest' is not null or undefined
            assertParamExists('createCluster', 'clusterCreationRequest', clusterCreationRequest)
            const localVarPath = `/api/v1/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster: async (clusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteCluster', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v1/clusters/{clusterUuid}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the cluster ancestors.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterAncestors: async (clusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getClusterAncestors', 'clusterUuid', clusterUuid)
            const localVarPath = `/internal/clusters/{clusterUuid}/ancestors`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get cluster by uuid
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterByUuid: async (clusterUuid: string, verbosity?: 'metadata' | 'full', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getClusterByUuid', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v1/clusters/{clusterUuid}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (verbosity !== undefined) {
                localVarQueryParameter['verbosity'] = verbosity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the installation instructions of a given cluster by ID. Relevant for clusters of version 2.15 or above. 
         * @summary Retrieve the installation instructions of a given cluster by ID
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} version The cluster version to install
         * @param {string} [remoteClusterUrl] The remote URL of the runai cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterInstallInfoByUuid: async (clusterUuid: string, version: string, remoteClusterUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getClusterInstallInfoByUuid', 'clusterUuid', clusterUuid)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getClusterInstallInfoByUuid', 'version', version)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/cluster-install-info`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (remoteClusterUrl !== undefined) {
                localVarQueryParameter['remoteClusterUrl'] = remoteClusterUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the cluster requested metrics data.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} start timestamp from when to fetch data in ISO 8601
         * @param {string} end timestamp until when to fetch data in ISO 8601
         * @param {Array<MetricsType>} metricType specifies what data to request
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterMetrics: async (clusterUuid: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getClusterMetrics', 'clusterUuid', clusterUuid)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getClusterMetrics', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getClusterMetrics', 'end', end)
            // verify required parameter 'metricType' is not null or undefined
            assertParamExists('getClusterMetrics', 'metricType', metricType)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/metrics`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }

            if (metricType) {
                localVarQueryParameter['metricType'] = metricType.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the cluster name.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterName: async (clusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getClusterName', 'clusterUuid', clusterUuid)
            const localVarPath = `/internal/clusters/{clusterUuid}/name`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List clusters.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusters: async (verbosity?: 'metadata' | 'full', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clusters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (verbosity !== undefined) {
                localVarQueryParameter['verbosity'] = verbosity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a cluster by id
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterUpdateRequest} clusterUpdateRequest The cluster details to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCluster: async (clusterUuid: string, clusterUpdateRequest: ClusterUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('updateCluster', 'clusterUuid', clusterUuid)
            // verify required parameter 'clusterUpdateRequest' is not null or undefined
            assertParamExists('updateCluster', 'clusterUpdateRequest', clusterUpdateRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update cluster-info (domain, version and status) for a specific cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterInfoSyncRequest} clusterInfoSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterInfo: async (clusterUuid: string, clusterInfoSyncRequest: ClusterInfoSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('updateClusterInfo', 'clusterUuid', clusterUuid)
            // verify required parameter 'clusterInfoSyncRequest' is not null or undefined
            assertParamExists('updateClusterInfo', 'clusterInfoSyncRequest', clusterInfoSyncRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/cluster-info`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterInfoSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update liveness status for a specific cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterLivenessSyncRequest} clusterLivenessSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterLiveness: async (clusterUuid: string, clusterLivenessSyncRequest: ClusterLivenessSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('updateClusterLiveness', 'clusterUuid', clusterUuid)
            // verify required parameter 'clusterLivenessSyncRequest' is not null or undefined
            assertParamExists('updateClusterLiveness', 'clusterLivenessSyncRequest', clusterLivenessSyncRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/liveness`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterLivenessSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClustersApi - functional programming interface
 * @export
 */
export const ClustersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClustersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create cluster
         * @param {ClusterCreationRequest} clusterCreationRequest The cluster to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCluster(clusterCreationRequest: ClusterCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisplayedCluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCluster(clusterCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCluster(clusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCluster(clusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the cluster ancestors.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterAncestors(clusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ancestors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterAncestors(clusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get cluster by uuid
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterByUuid(clusterUuid: string, verbosity?: 'metadata' | 'full', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisplayedCluster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterByUuid(clusterUuid, verbosity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the installation instructions of a given cluster by ID. Relevant for clusters of version 2.15 or above. 
         * @summary Retrieve the installation instructions of a given cluster by ID
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} version The cluster version to install
         * @param {string} [remoteClusterUrl] The remote URL of the runai cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterInstallInfoByUuid(clusterUuid: string, version: string, remoteClusterUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterInstallationInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterInstallInfoByUuid(clusterUuid, version, remoteClusterUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the cluster requested metrics data.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} start timestamp from when to fetch data in ISO 8601
         * @param {string} end timestamp until when to fetch data in ISO 8601
         * @param {Array<MetricsType>} metricType specifies what data to request
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterMetrics(clusterUuid: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterMetrics(clusterUuid, start, end, metricType, numberOfSamples, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the cluster name.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterName(clusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClusterName200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterName(clusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List clusters.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusters(verbosity?: 'metadata' | 'full', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DisplayedCluster>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusters(verbosity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a cluster by id
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterUpdateRequest} clusterUpdateRequest The cluster details to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCluster(clusterUuid: string, clusterUpdateRequest: ClusterUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCluster(clusterUuid, clusterUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update cluster-info (domain, version and status) for a specific cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterInfoSyncRequest} clusterInfoSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClusterInfo(clusterUuid: string, clusterInfoSyncRequest: ClusterInfoSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClusterInfo(clusterUuid, clusterInfoSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update liveness status for a specific cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterLivenessSyncRequest} clusterLivenessSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClusterLiveness(clusterUuid: string, clusterLivenessSyncRequest: ClusterLivenessSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClusterLiveness(clusterUuid, clusterLivenessSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClustersApi - factory interface
 * @export
 */
export const ClustersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClustersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create cluster
         * @param {ClusterCreationRequest} clusterCreationRequest The cluster to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCluster(clusterCreationRequest: ClusterCreationRequest, options?: any): AxiosPromise<DisplayedCluster> {
            return localVarFp.createCluster(clusterCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster(clusterUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCluster(clusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the cluster ancestors.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterAncestors(clusterUuid: string, options?: any): AxiosPromise<Ancestors> {
            return localVarFp.getClusterAncestors(clusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get cluster by uuid
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterByUuid(clusterUuid: string, verbosity?: 'metadata' | 'full', options?: any): AxiosPromise<DisplayedCluster> {
            return localVarFp.getClusterByUuid(clusterUuid, verbosity, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the installation instructions of a given cluster by ID. Relevant for clusters of version 2.15 or above. 
         * @summary Retrieve the installation instructions of a given cluster by ID
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} version The cluster version to install
         * @param {string} [remoteClusterUrl] The remote URL of the runai cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterInstallInfoByUuid(clusterUuid: string, version: string, remoteClusterUrl?: string, options?: any): AxiosPromise<ClusterInstallationInfoResponse> {
            return localVarFp.getClusterInstallInfoByUuid(clusterUuid, version, remoteClusterUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the cluster requested metrics data.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} start timestamp from when to fetch data in ISO 8601
         * @param {string} end timestamp until when to fetch data in ISO 8601
         * @param {Array<MetricsType>} metricType specifies what data to request
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterMetrics(clusterUuid: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options?: any): AxiosPromise<MetricsResponse> {
            return localVarFp.getClusterMetrics(clusterUuid, start, end, metricType, numberOfSamples, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the cluster name.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterName(clusterUuid: string, options?: any): AxiosPromise<GetClusterName200Response> {
            return localVarFp.getClusterName(clusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List clusters.
         * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusters(verbosity?: 'metadata' | 'full', options?: any): AxiosPromise<Array<DisplayedCluster>> {
            return localVarFp.getClusters(verbosity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a cluster by id
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterUpdateRequest} clusterUpdateRequest The cluster details to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCluster(clusterUuid: string, clusterUpdateRequest: ClusterUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCluster(clusterUuid, clusterUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update cluster-info (domain, version and status) for a specific cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterInfoSyncRequest} clusterInfoSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterInfo(clusterUuid: string, clusterInfoSyncRequest: ClusterInfoSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateClusterInfo(clusterUuid, clusterInfoSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update liveness status for a specific cluster
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterLivenessSyncRequest} clusterLivenessSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterLiveness(clusterUuid: string, clusterLivenessSyncRequest: ClusterLivenessSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateClusterLiveness(clusterUuid, clusterLivenessSyncRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClustersApi - object-oriented interface
 * @export
 * @class ClustersApi
 * @extends {BaseAPI}
 */
export class ClustersApi extends BaseAPI {
    /**
     * 
     * @summary Create cluster
     * @param {ClusterCreationRequest} clusterCreationRequest The cluster to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public createCluster(clusterCreationRequest: ClusterCreationRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).createCluster(clusterCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the cluster
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public deleteCluster(clusterUuid: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).deleteCluster(clusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the cluster ancestors.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterAncestors(clusterUuid: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterAncestors(clusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get cluster by uuid
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterByUuid(clusterUuid: string, verbosity?: 'metadata' | 'full', options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterByUuid(clusterUuid, verbosity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the installation instructions of a given cluster by ID. Relevant for clusters of version 2.15 or above. 
     * @summary Retrieve the installation instructions of a given cluster by ID
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} version The cluster version to install
     * @param {string} [remoteClusterUrl] The remote URL of the runai cluster
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterInstallInfoByUuid(clusterUuid: string, version: string, remoteClusterUrl?: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterInstallInfoByUuid(clusterUuid, version, remoteClusterUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the cluster requested metrics data.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} start timestamp from when to fetch data in ISO 8601
     * @param {string} end timestamp until when to fetch data in ISO 8601
     * @param {Array<MetricsType>} metricType specifies what data to request
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterMetrics(clusterUuid: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterMetrics(clusterUuid, start, end, metricType, numberOfSamples, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the cluster name.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterName(clusterUuid: string, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusterName(clusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List clusters.
     * @param {'metadata' | 'full'} [verbosity] response verbosity level. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusters(verbosity?: 'metadata' | 'full', options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getClusters(verbosity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a cluster by id
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {ClusterUpdateRequest} clusterUpdateRequest The cluster details to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public updateCluster(clusterUuid: string, clusterUpdateRequest: ClusterUpdateRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).updateCluster(clusterUuid, clusterUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update cluster-info (domain, version and status) for a specific cluster
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {ClusterInfoSyncRequest} clusterInfoSyncRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public updateClusterInfo(clusterUuid: string, clusterInfoSyncRequest: ClusterInfoSyncRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).updateClusterInfo(clusterUuid, clusterInfoSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update liveness status for a specific cluster
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {ClusterLivenessSyncRequest} clusterLivenessSyncRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public updateClusterLiveness(clusterUuid: string, clusterLivenessSyncRequest: ClusterLivenessSyncRequest, options?: AxiosRequestConfig) {
        return ClustersApiFp(this.configuration).updateClusterLiveness(clusterUuid, clusterLivenessSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstallationApi - axios parameter creator
 * @export
 */
export const InstallationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get cluster installation url
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterInstallationUrl: async (clusterUuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('clusterInstallationUrl', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/installation-url`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Map target url to cluster url
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterUrlRequest} clusterUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapClusterUrl: async (clusterUuid: string, clusterUrlRequest: ClusterUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('mapClusterUrl', 'clusterUuid', clusterUuid)
            // verify required parameter 'clusterUrlRequest' is not null or undefined
            assertParamExists('mapClusterUrl', 'clusterUrlRequest', clusterUrlRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/domain`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clusterUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstallationApi - functional programming interface
 * @export
 */
export const InstallationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstallationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get cluster installation url
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterInstallationUrl(clusterUuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterInstallationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterInstallationUrl(clusterUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Map target url to cluster url
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterUrlRequest} clusterUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapClusterUrl(clusterUuid: string, clusterUrlRequest: ClusterUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapClusterUrl(clusterUuid, clusterUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstallationApi - factory interface
 * @export
 */
export const InstallationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstallationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get cluster installation url
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterInstallationUrl(clusterUuid: string, options?: any): AxiosPromise<ClusterInstallationResponse> {
            return localVarFp.clusterInstallationUrl(clusterUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Map target url to cluster url
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ClusterUrlRequest} clusterUrlRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapClusterUrl(clusterUuid: string, clusterUrlRequest: ClusterUrlRequest, options?: any): AxiosPromise<ClusterUrlResponse> {
            return localVarFp.mapClusterUrl(clusterUuid, clusterUrlRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstallationApi - object-oriented interface
 * @export
 * @class InstallationApi
 * @extends {BaseAPI}
 */
export class InstallationApi extends BaseAPI {
    /**
     * 
     * @summary Get cluster installation url
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationApi
     */
    public clusterInstallationUrl(clusterUuid: string, options?: AxiosRequestConfig) {
        return InstallationApiFp(this.configuration).clusterInstallationUrl(clusterUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Map target url to cluster url
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {ClusterUrlRequest} clusterUrlRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstallationApi
     */
    public mapClusterUrl(clusterUuid: string, clusterUrlRequest: ClusterUrlRequest, options?: AxiosRequestConfig) {
        return InstallationApiFp(this.configuration).mapClusterUrl(clusterUuid, clusterUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalApi - axios parameter creator
 * @export
 */
export const InternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List cluster histories.
         * @param {Array<string>} clusterId The clusters Ids of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClustersHistories: async (clusterId: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getClustersHistories', 'clusterId', clusterId)
            const localVarPath = `/internal/cluster-histories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List cluster histories.
         * @param {Array<string>} clusterId The clusters Ids of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClustersHistories(clusterId: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClusterHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClustersHistories(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalApiFp(configuration)
    return {
        /**
         * 
         * @summary List cluster histories.
         * @param {Array<string>} clusterId The clusters Ids of the current request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClustersHistories(clusterId: Array<string>, options?: any): AxiosPromise<Array<ClusterHistory>> {
            return localVarFp.getClustersHistories(clusterId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
    /**
     * 
     * @summary List cluster histories.
     * @param {Array<string>} clusterId The clusters Ids of the current request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public getClustersHistories(clusterId: Array<string>, options?: AxiosRequestConfig) {
        return InternalApiFp(this.configuration).getClustersHistories(clusterId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodePoolsApi - axios parameter creator
 * @export
 */
export const NodePoolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the nodepool requested metrics data. [Experimental]
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodepoolName The unique nodepool name.
         * @param {string} start timestamp from when to fetch data in ISO 8601
         * @param {string} end timestamp until when to fetch data in ISO 8601
         * @param {Array<MetricsType>} metricType specifies what data to request
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodepoolMetrics: async (clusterUuid: string, nodepoolName: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getNodepoolMetrics', 'clusterUuid', clusterUuid)
            // verify required parameter 'nodepoolName' is not null or undefined
            assertParamExists('getNodepoolMetrics', 'nodepoolName', nodepoolName)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getNodepoolMetrics', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getNodepoolMetrics', 'end', end)
            // verify required parameter 'metricType' is not null or undefined
            assertParamExists('getNodepoolMetrics', 'metricType', metricType)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/nodepools/{nodepoolName}/metrics`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"nodepoolName"}}`, encodeURIComponent(String(nodepoolName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }

            if (metricType) {
                localVarQueryParameter['metricType'] = metricType.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodePoolsApi - functional programming interface
 * @export
 */
export const NodePoolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodePoolsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the nodepool requested metrics data. [Experimental]
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodepoolName The unique nodepool name.
         * @param {string} start timestamp from when to fetch data in ISO 8601
         * @param {string} end timestamp until when to fetch data in ISO 8601
         * @param {Array<MetricsType>} metricType specifies what data to request
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodepoolMetrics(clusterUuid: string, nodepoolName: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodepoolMetrics(clusterUuid, nodepoolName, start, end, metricType, numberOfSamples, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodePoolsApi - factory interface
 * @export
 */
export const NodePoolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodePoolsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the nodepool requested metrics data. [Experimental]
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodepoolName The unique nodepool name.
         * @param {string} start timestamp from when to fetch data in ISO 8601
         * @param {string} end timestamp until when to fetch data in ISO 8601
         * @param {Array<MetricsType>} metricType specifies what data to request
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodepoolMetrics(clusterUuid: string, nodepoolName: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options?: any): AxiosPromise<MetricsResponse> {
            return localVarFp.getNodepoolMetrics(clusterUuid, nodepoolName, start, end, metricType, numberOfSamples, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodePoolsApi - object-oriented interface
 * @export
 * @class NodePoolsApi
 * @extends {BaseAPI}
 */
export class NodePoolsApi extends BaseAPI {
    /**
     * 
     * @summary Get the nodepool requested metrics data. [Experimental]
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} nodepoolName The unique nodepool name.
     * @param {string} start timestamp from when to fetch data in ISO 8601
     * @param {string} end timestamp until when to fetch data in ISO 8601
     * @param {Array<MetricsType>} metricType specifies what data to request
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodePoolsApi
     */
    public getNodepoolMetrics(clusterUuid: string, nodepoolName: string, start: string, end: string, metricType: Array<MetricsType>, numberOfSamples?: number, options?: AxiosRequestConfig) {
        return NodePoolsApiFp(this.configuration).getNodepoolMetrics(clusterUuid, nodepoolName, start, end, metricType, numberOfSamples, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodesApi - axios parameter creator
 * @export
 */
export const NodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete the node as is does not exist in the cluster anymore.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeName The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNode: async (clusterUuid: string, nodeName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteNode', 'clusterUuid', clusterUuid)
            // verify required parameter 'nodeName' is not null or undefined
            assertParamExists('deleteNode', 'nodeName', nodeName)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/nodes/{nodeName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"nodeName"}}`, encodeURIComponent(String(nodeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get node telemetry
         * @param {NodeTelemetryType} telemetryType specifies what data to request
         * @param {string} [clusterId] cluster id to filter by
         * @param {string} [nodepoolName] nodepool to filter by
         * @param {Array<'ClusterId' | 'Nodepool' | 'Node'>} [groupBy] workload fields to group the data by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeTelemetry: async (telemetryType: NodeTelemetryType, clusterId?: string, nodepoolName?: string, groupBy?: Array<'ClusterId' | 'Nodepool' | 'Node'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'telemetryType' is not null or undefined
            assertParamExists('getNodeTelemetry', 'telemetryType', telemetryType)
            const localVarPath = `/api/v1/nodes/telemetry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (nodepoolName !== undefined) {
                localVarQueryParameter['nodepoolName'] = nodepoolName;
            }

            if (groupBy) {
                localVarQueryParameter['groupBy'] = groupBy.join(COLLECTION_FORMATS.csv);
            }

            if (telemetryType !== undefined) {
                localVarQueryParameter['telemetryType'] = telemetryType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List nodes.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodeName] The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodes: async (clusterUuid: string, nodeName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getNodes', 'clusterUuid', clusterUuid)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/nodes`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nodeName !== undefined) {
                localVarQueryParameter['nodeName'] = nodeName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync the nodes in the cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {NodesSyncRequest} nodesSyncRequest The nodes info for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncNodes: async (clusterUuid: string, nodesSyncRequest: NodesSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncNodes', 'clusterUuid', clusterUuid)
            // verify required parameter 'nodesSyncRequest' is not null or undefined
            assertParamExists('syncNodes', 'nodesSyncRequest', nodesSyncRequest)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/nodes/sync`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodesSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert the node info from the cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeName The node name.
         * @param {NodeInfo} nodeInfo The node info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertNode: async (clusterUuid: string, nodeName: string, nodeInfo: NodeInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('upsertNode', 'clusterUuid', clusterUuid)
            // verify required parameter 'nodeName' is not null or undefined
            assertParamExists('upsertNode', 'nodeName', nodeName)
            // verify required parameter 'nodeInfo' is not null or undefined
            assertParamExists('upsertNode', 'nodeInfo', nodeInfo)
            const localVarPath = `/api/v1/clusters/{clusterUuid}/nodes/{nodeName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"nodeName"}}`, encodeURIComponent(String(nodeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodesApi - functional programming interface
 * @export
 */
export const NodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete the node as is does not exist in the cluster anymore.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeName The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNode(clusterUuid: string, nodeName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNode(clusterUuid, nodeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get node telemetry
         * @param {NodeTelemetryType} telemetryType specifies what data to request
         * @param {string} [clusterId] cluster id to filter by
         * @param {string} [nodepoolName] nodepool to filter by
         * @param {Array<'ClusterId' | 'Nodepool' | 'Node'>} [groupBy] workload fields to group the data by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodeTelemetry(telemetryType: NodeTelemetryType, clusterId?: string, nodepoolName?: string, groupBy?: Array<'ClusterId' | 'Nodepool' | 'Node'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelemetryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodeTelemetry(telemetryType, clusterId, nodepoolName, groupBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List nodes.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodeName] The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodes(clusterUuid: string, nodeName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Nodes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodes(clusterUuid, nodeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync the nodes in the cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {NodesSyncRequest} nodesSyncRequest The nodes info for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncNodes(clusterUuid: string, nodesSyncRequest: NodesSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncNodes(clusterUuid, nodesSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsert the node info from the cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeName The node name.
         * @param {NodeInfo} nodeInfo The node info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertNode(clusterUuid: string, nodeName: string, nodeInfo: NodeInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertNode(clusterUuid, nodeName, nodeInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodesApi - factory interface
 * @export
 */
export const NodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodesApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete the node as is does not exist in the cluster anymore.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeName The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNode(clusterUuid: string, nodeName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNode(clusterUuid, nodeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get node telemetry
         * @param {NodeTelemetryType} telemetryType specifies what data to request
         * @param {string} [clusterId] cluster id to filter by
         * @param {string} [nodepoolName] nodepool to filter by
         * @param {Array<'ClusterId' | 'Nodepool' | 'Node'>} [groupBy] workload fields to group the data by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeTelemetry(telemetryType: NodeTelemetryType, clusterId?: string, nodepoolName?: string, groupBy?: Array<'ClusterId' | 'Nodepool' | 'Node'>, options?: any): AxiosPromise<TelemetryResponse> {
            return localVarFp.getNodeTelemetry(telemetryType, clusterId, nodepoolName, groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List nodes.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodeName] The node name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodes(clusterUuid: string, nodeName?: string, options?: any): AxiosPromise<Nodes> {
            return localVarFp.getNodes(clusterUuid, nodeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync the nodes in the cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {NodesSyncRequest} nodesSyncRequest The nodes info for sync.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncNodes(clusterUuid: string, nodesSyncRequest: NodesSyncRequest, options?: any): AxiosPromise<void> {
            return localVarFp.syncNodes(clusterUuid, nodesSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert the node info from the cluster.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeName The node name.
         * @param {NodeInfo} nodeInfo The node info in the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertNode(clusterUuid: string, nodeName: string, nodeInfo: NodeInfo, options?: any): AxiosPromise<void> {
            return localVarFp.upsertNode(clusterUuid, nodeName, nodeInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodesApi - object-oriented interface
 * @export
 * @class NodesApi
 * @extends {BaseAPI}
 */
export class NodesApi extends BaseAPI {
    /**
     * 
     * @summary Delete the node as is does not exist in the cluster anymore.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} nodeName The node name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public deleteNode(clusterUuid: string, nodeName: string, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).deleteNode(clusterUuid, nodeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get node telemetry
     * @param {NodeTelemetryType} telemetryType specifies what data to request
     * @param {string} [clusterId] cluster id to filter by
     * @param {string} [nodepoolName] nodepool to filter by
     * @param {Array<'ClusterId' | 'Nodepool' | 'Node'>} [groupBy] workload fields to group the data by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public getNodeTelemetry(telemetryType: NodeTelemetryType, clusterId?: string, nodepoolName?: string, groupBy?: Array<'ClusterId' | 'Nodepool' | 'Node'>, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).getNodeTelemetry(telemetryType, clusterId, nodepoolName, groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List nodes.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} [nodeName] The node name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public getNodes(clusterUuid: string, nodeName?: string, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).getNodes(clusterUuid, nodeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync the nodes in the cluster.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {NodesSyncRequest} nodesSyncRequest The nodes info for sync.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public syncNodes(clusterUuid: string, nodesSyncRequest: NodesSyncRequest, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).syncNodes(clusterUuid, nodesSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert the node info from the cluster.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} nodeName The node name.
     * @param {NodeInfo} nodeInfo The node info in the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodesApi
     */
    public upsertNode(clusterUuid: string, nodeName: string, nodeInfo: NodeInfo, options?: AxiosRequestConfig) {
        return NodesApiFp(this.configuration).upsertNode(clusterUuid, nodeName, nodeInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


